.novedades {
    .layout {
        .main {
            background-color: $bg-7;
            padding-top: 7rem;
            padding-bottom: 5rem;
            min-height: fit-content;
            .container-fluid {
                .hilera {
                    .div_selected {
                        .div_search {
                            border: 1px solid $bd-0;
                            border-radius: 0px;
                            background-color: $bg-1;
                            .div_first_search {
                                .icon-label-solid {
                                    color: $color-0;
                                    max-width: min-content;
                                }
                                button {
                                    background: transparent;
                                    border: none;
                                }
                            }
                            .div_second_search {
                                .clean-btn {
                                    color: $color-0;
                                }
                                button {
                                    color: $color-5;
                                    font-size: 1.3rem;
                                    background-color: $bg-0;
                                    padding: 2rem 6rem 2rem 4rem;
                                    i {
                                        position: absolute;
                                        right: 4rem;
                                        font-size: 1.5rem;
                                        transform: rotate(85deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .novedades {
        .layout {
            .main {
                padding-top: 6rem;
                padding-bottom: 3rem;
                .container-fluid {
                    max-width: none;
                    .div_breadcrums {
                        margin-bottom: 3rem;
                    }
                    .hilera {
                        .div_selected {
                            .div_search {
                                padding: 1rem 1.3rem;
                                .div_first_search {
                                    .icon-label-solid {
                                        font-size: 2rem !important;
                                    }
                                    button {}
                                }
                                .div_second_search {
                                    // width: 100%;
                                    // overflow: scroll;
                                    // height: 3rem;
                                    // width: 3rem;
                                    button {
                                        height: 3rem;
                                        padding: 0.5rem 1.7rem 0.5rem 1.7rem;
                                        background-color: $bg-1;
                                        color: $color-0;
                                        border: none;
                                        i {
                                            right: 1rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}