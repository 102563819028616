.propiedad {
    .data {
        background-color: $bg-7;
        padding-top: 7rem;
        overflow: hidden;
        max-width: 100vw;
        .primer_bloque {
            margin-bottom: 5rem;
            .div_cabecera {
                margin-bottom: 5rem !important;
                .breadcrumb {
                    .breadcrumb-item {
                      opacity: 0.7;
                      color: $color-12 !important;
                      a {
                        color: $color-12 !important;
                      }
                      &::before {
                        color: $color-12 !important;
                      }
                    }
                    &:last-child {
                      .breadcrumb-item,
                      .breadcrumb-item:last-child::before {
                        opacity: 1;
                        color: $color-13 !important;
                      }
                    }
                  }
                // .div_news__title {
                //     position: absolute;
                //     margin-top: -14vh;
                //     h2 {
                //         color: $color-5;
                //         opacity: 0.14;
                //         &:first-child {
                //             margin-left: -17%;
                //             margin-right: 19rem;
                //         }
                //     }
                // }
            }
            .primera_col,
            .tercera_col {
                h2 {
                    font-size: 3rem;
                    margin-bottom: 0;
                    line-height: 1;
                    color: $color-2;
                    font-weight: 200;
                    // &.sub_title {
                    //     font-weight: 200;
                    //     color: #fff;
                    //     margin-top: 10vh;
                    // }
                }
                .div_items {
                    .item {
                        color: #fff;
                        text-align: center;
                        .tick {
                            width: 1.5rem;
                            height: 1.5rem;
                            border-radius: 0 !important;
                            i {
                                color: #fff;
                                font-size: 0.5rem;
                            }
                        }
                    }
                    .item-extra {
                        margin-top: 0.5rem;
                        font-size: 1.5rem;
                        color: $color-2;
                        align-items: center;
                        font-family: $font-1;
                        div {
                            &::after {
                                content: "|";
                                margin: 0 7px;
                                font-size: 2rem;
                                position: relative;
                            }
                            &:last-child {
                                &::after {
                                    content: "";
                                }
                            }
                        }
                        &.surface {
                            color: $color-2;
                            i {
                                color: $color-2;
                            }
                        }
                        i {
                            color: $color-13;
                            text-align: center;
                            font-size: 2.5rem;
                        }
                        .separate-items {
                            margin-left: 1rem;
                            margin-right: 1rem;
                            color: $color-2;
                        }
                    }
                }
            }
            .segunda_col {
                // border: 1px solid $bd-5;
                padding: 4rem;
                .rotative-logo-wrap {
                    top: -77px;
                    left: -90px;
                    .circle_desarrollo {
                        opacity: 0.3;
                        width: 18rem;
                    }
                    .logo-center-letter {
                        width: 9rem;
                        opacity: 0.3;
                    }
                }
                p {
                    color: $color-2;
                    font-family: $font-1;
                    line-height: 2.2rem;
                    width: 100%;
                    font-weight: 200;
                    a.see_description,
                    a.see_description:hover {
                        text-decoration: none;
                        color: $color-12;
                    }
                }
                .content-price {
                    display: block;
                    margin: 5rem 0 !important;
                    margin-bottom: 2rem !important;
                    h3 {
                        color: $color-13;
                    }
                }
                hr {
                    height: 2px;
                    opacity: 1;
                    color: $color-8;
                    width: 100%;
                }
            }
        }
        .segundo_bloque {
            // background-color: $bg-13;
            padding-top: 5rem;
            padding-bottom: 5rem;
            .container-fluid {
                width: 90%;
                border: 1px solid $bd-9;
                background-color: $bg-17;
                font-family: $font-1;
                padding: 2.5rem;
                .amenities {
                    h3 {
                        color: $color-2;
                        font-size: 2.5rem;
                    }
                    .hilera_amenities {
                        .item {
                            color: $color-13;
                            font-size: 1.3rem;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            // max-width: 175px;
                            i {
                                font-size: 1rem;
                                background-color: $bg-18;
                                margin-right: 1rem;
                                color: $color-7;
                                padding: 0.3rem;
                            }
                        }
                    }
                }
            }
        }
        .tercer_bloque {
            padding-top: 10rem;
            padding-bottom: 5rem;
            .div_news__title {
                // position: absolute;
                // margin-top: -12vh;
                h2 {
                    color: $color-13;
                    font-size: 8rem;
                    // &:first-child {
                    //     margin-left: 0%;
                    //     margin-right: 45%;
                    // }
                }
            }
            .js-projects-gallery {
                section.clients {
                    margin-top: 1em;
                    margin-bottom: 4em;
                }
            }
        }
        .cuarto_bloque {
            // .div_news__title {
            //     position: absolute;
            //     margin-top: 7%;
            //     h2 {
            //         color: $color-5;
            //         opacity: 0.14;
            //         &:first-child {
            //             margin-left: 0%;
            //             margin-right: 45%;
            //         }
            //     }
            // }
            // h2 {
            //     color: $color-8;
            // }
            // h5 {
            //     color: $color-5;
            // }
            .location-section {
                .contact-container {
                    color: $color-2;
                      &::before {
                        top: 45%;
                        left: 10%;
                        z-index: 1;
                        opacity: 0.05;
                        color: $color-2;
                        font-size: 16rem;
                        position: absolute;
                        white-space: nowrap;
                        content: "Mapa";
                        transform: translate(-50%, -50%);
                    }
                    h2 {
                        color: $color-13;
                    }
                }
            }
        }
        .project {
            width: 100% !important;
            height: 100% !important;
            max-width: 940px !important;
            max-height: 677px !important;
        }
    }
}

@media (max-width: 1500px) {
    .propiedad {
        .data {
            .primer_bloque {
                .div_cabecera {
                    .breadcrumb {
                        .breadcrumb-item {
                            a {}
                            &:nth-last-child(1) {}
                        }
                        .breadcrumb-item+.breadcrumb-item {
                            &::before {}
                            a {}
                        }
                    }
                    // .div_news__title {
                    //     margin-top: -6vh;
                    //     h2 {
                    //         &:first-child {
                    //             margin-left: -16%;
                    //             margin-right: 23rem;
                    //         }
                    //     }
                    // }
                }
                .primera_col,
                .tercera_col {
                    h2 {
                        &.sub_title {}
                    }
                    .div_items {
                        .item {
                            .tick {
                                i {}
                            }
                        }
                        .item-extra {
                            &.surface {}
                            i {}
                            .separate-items {}
                        }
                    }
                }
                .segunda_col {
                    padding: 1rem 2rem;
                    p {}
                    .content-price {
                        h3 {}
                    }
                    hr {}
                }
            }
            .segundo_bloque {
                .amenities {
                    h3 {}
                    .hilera_amenities {
                        .item {
                            i {}
                        }
                    }
                }
            }
            .tercer_bloque {
                .div_news__title {
                    h2 {
                        &:first-child {}
                    }
                }
            }
            .cuarto_bloque {
                .div_news__title {
                    h2 {
                        &:first-child {}
                    }
                }
                h2 {}
                h5 {}
            }
            .project {}
        }
    }
}

@media (max-width: 992px) {
    .propiedad {
        .data {
            .primer_bloque {
                margin-bottom: 0rem;
                .div_cabecera {
                    .breadcrumb {
                        .breadcrumb-item {
                            a {}
                            &:nth-last-child(1) {}
                        }
                        .breadcrumb-item+.breadcrumb-item {
                            &::before {}
                            a {}
                        }
                    }
                    // .div_news__title {
                    //     margin-top: -6vh;
                    //     h2 {
                    //         &:first-child {
                    //             margin-left: -24%;
                    //             margin-right: 5rem;
                    //         }
                    //     }
                    // }
                }
                .primera_col,
                .tercera_col {
                    h2 {
                        &.sub_title {}
                    }
                    .div_items {
                        padding-left: 1rem;
                        .item {
                            .tick {
                                i {}
                            }
                        }
                        .item-extra {
                            &.surface {}
                            i {}
                            .separate-items {}
                        }
                    }
                }
                .segunda_col {
                    border: none;
                    p {}
                    .content-price {
                        h3 {}
                    }
                    hr {}
                }
            }
            .segundo_bloque {
                .amenities {
                    h3 {}
                    .hilera_amenities {
                        .item {
                            i {}
                        }
                    }
                }
            }
            .tercer_bloque {
                .div_news__title {
                    h2 {
                        &:first-child {}
                    }
                }
            }
            .cuarto_bloque {
                .div_news__title {
                    h2 {
                        &:first-child {}
                    }
                }
                h2 {}
                h5 {}
            }
            .project {}
        }
    }
}