.novedades {
  .layout {
    .content {
      .div_blog {
        position: relative;
        // padding: 7rem 0rem 0rem 0rem;
        background-color: $bg-7;
        display: flex;
        align-items: center;
        .container-fluid {
          margin-right: inherit;
          max-width: 100%;
          // border-left: 2px solid #1c1c1c;
          .div_blog_container {
            padding: 0 10rem 10rem 10rem;
            position: relative;
            margin-top: 10rem;
            .div_cards-news {
              div {
                .div_card_new {
                  z-index: 2;
                  .card_new {
                    width: 40%;
                  }
                }
              }
              &::before {
                top: -2%;
                left: 20%;
                z-index: 0;
                opacity: 0.05;
                color: $color-2;
                font-size: 16rem;
                position: absolute;
                white-space: nowrap;
                content: "Actualidad";
                transform: translate(-50%, -40%);
              }
              &::after {
                bottom: 0%;
                right: 0%;
                z-index: 0;
                opacity: 0.05;
                color: $color-2;
                font-size: 16rem;
                position: absolute;
                white-space: nowrap;
                content: "Greenville";
                transform: translate(20%, 0%);
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1918px) {
  .novedades {
    .layout {
      .content {
        .div_blog {
          .container-fluid {
            .div_blog_container {
              .div_cards-news {
                div {
                  .div_card_new {
                    .card_new {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .novedades {
    .layout {
      .content {
        .div_blog {
          .container-fluid {
            .div_blog_container {
              .div_cards-news {
                div {
                  .div_card_new {
                    .card_new {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .novedades {
    .layout {
      .content {
        .div_blog {
          .container-fluid {
            .div_blog_container {
              .div_cards-news {
                div {
                  .div_card_new {
                    .card_new {
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .novedades {
    .layout {
      .content {
        .container-fluid {
          max-width: 100% !important;
          .div_blog {
            .container-fluid {
              .div_blog_container {
                padding: 0 0rem 10rem 1rem !important;
                .div_cards-news {
                  div {
                    .div_card_new {
                      .card_new {
                        width: 60%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
