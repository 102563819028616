.main__first__section__desarrollo {
  width: 100vw;
  .main__first__section__container__desarrollo {
    position: relative;
    width: 100%;
    .main__first__section__background__desarrollo {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }
    .main__first__section__title__desarrollo {
      position: absolute;
      top: 0%;
      color: var(--white);
      height: 100vh;
      width: 100%;
      left: 10rem;
      //   background: rgb(0, 0, 0);
      //   background: linear-gradient(180deg, #202020a7 60%, #151a2fc7 90%);
      .main__first__section__title__container__desarrollo {
        h1 {
          color: $color-1;
        }
        .div_bottom_arrow {
          //   transform: rotate(90deg);
          margin-top: 3rem;
          z-index: 1;
          position: relative;
          a {
            border: 1px solid white;
            border-radius: 49%;
            padding: 2.2rem;
            position: absolute;
            transition: 1s all;
            span {
              position: absolute;
              font-size: 1rem;
              color: white;
              top: 25px;
              left: 26px;
              transform: rotate(90deg);
              transition: 1s all;
            }
            &:hover {
              // border-color: $bd-5;
              span {
                top: 35px;
                // color: $color-8;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .main__first__section__title__container__desarrollo {
    margin-top: 10vh !important;
    p {
      font-size: 1.2rem;
      br {
        display: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .main__first__section__desarrollo {
    .main__first__section__container__desarrollo {
      .main__first__section__background__desarrollo {
      }
      .main__first__section__title__desarrollo {
        left: 0;
        .main__first__section__title__container__desarrollo {
          h1 {
          }
        }
      }
    }
  }
}
