.Inicio {
    .main__first__section__home {
      width: 100vw;
      .main__first__section__container__home {
        position: relative;
        width: 100%;
        .main__first__section__background__home {
          width: 100%;
          height: 100vh;
          object-fit: cover;
          object-position: center;
        }
        .main__first__section__title__home {
          position: absolute;
          top: 0%;
          color: var(--white);
          height: 100vh;
          width: 100%;
          left: 5rem;
        }
        &::before {
          position: absolute;
          content: "";
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #1c1c1c31 0% 0% no-repeat padding-box;
        }
      }
    }
    .button-video {
        background-color: #ffffff12;
        backdrop-filter: blur(2px);
        -webkit-backdrop-filter: blur(2px);
        color: var(--white) !important;
        border: 2px solid $bd-1;
        box-sizing: border-box;
        border-radius: 5px;
        padding: 25px;
        color: $color-1;
        &:hover {
            opacity: 0.8;
        }
    }
  }
  
  @media (max-width: 992px) {
    .Inicio {
      .main__first__section__home {
        .main__first__section__container__home {
          .main__first__section__title__home {
            left: 0;
          }
        }
      }
    }
  }
  
  @media (max-width: 768px) {
    .Inicio {
      .main__first__section__title__container__home {
        margin-top: 10vh !important;
      }
    }
  }
  