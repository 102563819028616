.buttonGhost,
.buttonGhostTwo {
  position: relative;
  padding: 2rem 7rem 2rem 4rem;
  color: $color-0;
  background-color: $bg-7;
  transition: all 0.3s;
  font-size: 1.5rem;
  border: 1px solid $bd-0;
  text-decoration: none;
  letter-spacing: 1px;
  a {
    text-decoration: none;
  }
  &:hover {
    background-color: $bg-1;
    text-decoration: none;
    color: $color-0;
    span {
      i {
        left: 3rem !important;
        transition: 0.5s all;
      }
    }
  }
  span {
    position: relative;
    i {
      font-size: 2rem;
      position: absolute;
      top: -0.9rem;
      left: 2rem;
    }
  }
}

@media (max-width: 992px) {
  .buttonGhost,
  .buttonGhostTwo {
    padding: 1.5rem 5rem 1.5rem 3rem;
    // font-size: 0.9rem;
    &:hover {span {
        i {
          left: 2rem !important;
        }
      }
    }
  }
}
