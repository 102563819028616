.Emprendimiento {
  .content {
    background-color: $bg-7;
    .section {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 7rem!important;
      .container-fluid {
        padding: 17vh 0 6vh 0;
        overflow: hidden;
        position: relative;
        .div_breadcrumbs {
          .breadcrumb {
            .breadcrumb-item {
              opacity: 0.7;
              color: $color-12 !important;
              a {
                color: $color-12 !important;
              }
              &::before {
                color: $color-12 !important;
              }
            }
            &:last-child {
              .breadcrumb-item,
              .breadcrumb-item:last-child::before {
                opacity: 1;
                color: $color-13 !important;
              }
            }
          }
        }
        .div_descripcion {
          margin-top: 5rem;
          .first_div {
            h3 {
              color: $color-2;
            }
            p {
              color: $color-2;
              opacity: .5;
            }
          }
          .second_div {
            align-items: center;
            h2 {
              color: $color-3;
              position: relative;
              width: fit-content;
            }
          }
          .features-section {
            .features-text {
              color: $color-2;
              margin-bottom: 3rem;
            }
            .iconos_descripcion {
              display: flex;
              .div_icon {
                display: block;
                i {
                  padding-left: 1rem;
                  padding-right: 1rem;
                  font-size: 3rem;
                  color: $color-12;
                  display: block;
                }
                span {
                  display: block;
                  color: $color-13;
                  text-align: center;
                }
              }
              .amenities {
                padding-right: 4rem;
                border-right: 1px solid $bd-2;
                @media (max-width: 768px) {
                  padding-right: 2rem;
                }
              }
              .oportunidad {
                padding-right: 4rem;
                padding-left: 4rem;
                border-right: 1px solid $bd-2;
                @media (max-width: 768px) {
                  padding-right: 2rem;
                  padding-left: 2rem;
                }
              }
              .ubicacion {
                padding-left: 4rem;
                @media (max-width: 768px) {
                  padding-left: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.lading-project-about {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.lading-project-about > div:nth-child(2) {
  padding-left: 3em;
}

.lading-project-about h4 {
  font-size: 1.3rem;
  letter-spacing: 2px;
  /* font-family: 'montserrat semibold', sans-serif; */
  margin-top: 5px;
}
@media (max-width: 768px) {
  .lading-project-about {
    grid-template-columns: 1fr;
  }
  .lading-project-about > div:nth-child(2) {
    padding-left: 0;
  }
}
.lading-project-about p {
  font-size: 1rem;
}
.lading-project-about h4 {
  font-size: 1rem;
}

@media (max-width: 992px) {
  .Emprendimiento {
    .content {
      .section {
        .container-fluid {
          padding: 17vh 10vw 6vh 10vw;

          .div_breadcrumbs {
            .breadcrumb {
              .breadcrumb-item {
                &::before {
                }
              }
            }
          }
          .div_descripcion {
            .first_div {
              h3 {
              }
              p {
              }
            }
            .second_div {
              align-items: center;
              h2 {
                width: fit-content;
                .circle_desarrollo {
                  top: -2rem;
                  right: -3rem;
                  width: 8rem;
                }
              }
            }
            .iconos_descripcion {
              .div_icon {
                i {
                }
                span {
                }
                &:nth-child(2) {
                  i {
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
