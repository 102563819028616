.div_news {
  position: relative;
  padding: 7rem 0rem 12rem 0rem;
  background-color: $bg-4;
  display: flex;
  align-items: center;
  .div_news__title {
    h2 {
      z-index: 2;
      color: $color-2;
      font-size: 6rem;
      // &:first-child {
        //   margin-left: -23rem;
        //   margin-right: 23rem;
        // }
      }
    &::before {
      top: -25%;
      @media (max-width: 991px) {
        top: -10%;
      }
      left: 80%;
      z-index: 1;
      opacity: 0.05;
      color: $color-2;
      font-size: 16rem;
      position: absolute;
      white-space: nowrap;
      content: "Novedades";
      transform: translate(-50%, -50%);
    }
  }
  .container {
    // margin-right: inherit;
    // max-width: 90%;
    // border-left: 2px solid #1c1c1c;
    .div_news_container {
      // padding: 0 10rem 11rem 10rem;
      // border-left: 2px solid $bd-1;
      position: relative;
      .pill {
        overflow: hidden !important;
        white-space: nowrap !important;
        text-overflow: ellipsis;
      }
      .div_vertical {
        p {
          transform: rotate(180deg);
          margin: 0;
          position: absolute;
          left: -20px;
          writing-mode: tb-rl;
          color: #1c1c1c;
          top: 0rem;
          background-color: $bg-4;
          padding-top: 3rem;
        }
      }
      .div_new_quarter {
        margin-top: 4rem;
        a {
          display: flex;
          align-items: center;
          text-decoration: none;
        }
      }
    }
  }
  // .circle_construfac {
  //   position: absolute;
  //   bottom: -9%;
  //   right: 10%;
  //   z-index: 1;
  // }
}

@media (max-width: 1300px) {
  .div_news {
    .div_news__title {
      h2 {
        &:first-child {
        }
      }
    }
    .container-fluid {
      .div_news_container {
        // padding: 0 4rem 11rem 4rem;
        .div_vertical {
          p {
          }
        }
        .div_new_card {
          .card_new {
          }
        }
        .div_new_first {
        }
        .div_new_second {
          img {
          }
          .img-1 {
          }
          .img-2 {
          }
          .img-3 {
          }
        }
        .div_new_third {
          h3 {
          }
          div {
            p {
            }
          }
        }
        .div_new_quarter {
          a {
            &:hover {
            }
          }
        }
      }
    }
    // .circle_construfac {
    //   bottom: -12%;
    // }
  }
}

@media (max-width: 992px) {
  .div_news {
    .div_news__title {
      h2 {
        // &:first-child {
        //   margin-left: -33rem;
        //   margin-right: 7rem;
        // }
        text-align: center;
      }
    }
    .container-fluid {
      .div_news_container {
        padding: 0 0rem 11rem 4rem;
        .div_vertical {
          p {
            left: -14px;
          }
        }
        .div_new_card {
          .card_new {
            height: 450px;
          }
        }
        .div_new_first {
        }
        .div_new_second {
          img {
          }
          .img-1 {
          }
          .img-2 {
          }
          .img-3 {
          }
        }
        .div_new_third {
          h3 {
          }
          div {
            p {
            }
          }
        }
        .div_new_quarter {
          a {
            &:hover {
            }
          }
        }
      }
    }
    // .circle_construfac {
    //   bottom: -5%;
    //   right: 10%;
    //   width: 11rem;
    // }
  }
}
