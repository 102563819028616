.div_card_new {
  // width: 100%;
  // height: 100%;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  .img_card {
    transition: 1s ease all !important;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .card_new {
    padding: 2rem !important;
    width: 60%;
    // height: 512px;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    background-color: $bg-2;
    .content_card {
      .content_body {
        padding: 0px 1rem 0 0;
        .card_date {
          color: $color-8;
        }
        h3 {
          font-size: 2rem;
          color: $color-2;
          min-height: 8rem;
        }
        p {
          color: $color-2;
          min-height: 10rem;
        }
      }
      .publicated_by {
        p {
          color: $color-2;
        }
      }
      .div_pill{
        min-height: 3rem;
      }
      .see_more {
        text-decoration: none;
        align-items: center;
        align-items: baseline;
        font-size: 1.2rem;
        span.link-text {
          color: $color-0;
          font-family: $font-1;
          text-decoration: none;
        }
        i {
          color: $color-0;
          margin-left: 5px;
          transition: 0.5s all;
        }
        p {
          font-size: 0.8rem;
          margin: 0;
          margin-left: 0.8rem;
          color: $color-4;
        }
      }
    }
  }
  &:hover {
    .img_card {
      transform: scale(1.2);
      transition: 2s ease all !important;
    }
    .card_new {
      .content_card {
        .see_more {
          span.link-text {
            color: $color-0;
          }
          i {
            margin-left: 10px;
          }
          p {
            color: $color-0;
            margin-left: 1.5rem;
            transition: 1s all;
          }
        }
      }
    }
  }
}

@media (max-width: 1300px) {
  .div_card_new {
    .card_new {
      padding: 1.5rem !important;
      .content_card {
        .content_body {
          padding: 0 0 0 0;
          .card_date {
          }
          h3 {
          }
          p {
          }
        }
        .div_pill {
          .pill {
            padding: 0.2rem 1rem;
            margin-right: 0.5rem;
          }
        }
        hr {
        }
        .publicated_by {
          p {
          }
        }
        .see_more {
          span.link-text {
          }
          .icon-arrow-light {
          }
        }
      }
    }
    &:hover {
    }
  }
}
