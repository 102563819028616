.projects {
    background: white;
    position: relative;
    .container-1 {
        padding: 3rem 0rem 3rem 0rem;
        @media (max-width: 991px) { 
            padding: 0rem 0rem 3rem 0rem;
        }
        .div_text_container {
            justify-content: space-between;
            h2 {
                color: $color-2;
            }
            p.text-content {
                color: $color-2;
                max-width: 355px;
                font-size: 1.25rem;
            }
            .div_arrows {
                align-items: flex-end;
                div {
                    height: 4rem;
                    width: 4rem;
                    border: 1px solid $bd-0;
                    border-radius: 50%;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .left {
                    margin-right: 4.5rem;
                    position: relative;
                    i {
                        font-size: 2rem;
                        transform: rotate(180deg);
                        color: $color-0;
                        transition: 0.5s all;
                    }
                    &::after {
                        font-size: 1.2rem;
                        font-family: $font-1;
                        color: $color-0;
                        transition: 0.5s all;
                        content: 'Anterior';
                        background-color: $bg-4;
                        position: absolute;
                        width: 70px;
                        left: 27px;
                        opacity: 0;
                        transition: 0.5s all;
                    }
                    &:hover {
                        cursor: pointer;
                        i {
                            margin-right: 5px;
                        }
                    }
                    &:hover::after {
                        opacity: 1;
                    }
                }
                .right {
                    margin-right: 4rem;
                    position: relative;
                    i {
                        font-size: 2rem;
                        color: $color-0;
                        transition: 0.5s all;
                    }
                    &::before {
                        font-size: 1.2rem;
                        font-family: $font-1;
                        color: $color-0;
                        content: 'Siguiente';
                        background-color: $bg-4;
                        position: absolute;
                        width: 75px;
                        right: 30px;
                        opacity: 0;
                        transition: 0.5s all;
                    }
                    &:hover {
                        transition: 0.5s all;
                        cursor: pointer;
                        i {
                            margin-left: 5px;
                        }
                    }
                    &:hover::before {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .container-2 {
        padding: 0rem 0rem 2rem 0rem;
        .section-content {
            margin-top: 1rem;
            .owl-carousel .owl-stage-outer .owl-stage {
                // width: 5500px !important;
                height: 62vh;
                overflow: hidden;
                .owl-item {
                    // width: 550px !important;
                }
            }
            .owl-carousel .owl-nav {
                display: none;
            }
        }
    }
}

@media (max-width: 1500px) {
    .projects {
        .fluid-1 {
            .div_heard_project {
                h2 {
                    span {}
                }
                .div_arrows {}
            }
            p {
                // left: -34px;
            }
        }
        .fluid-2 {
            .section-content {
                .owl-carousel .owl-stage-outer .owl-stage {
                    .owl-item {}
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .projects {
        .fluid-1 {
            padding: 9rem 0rem 3rem 3rem;
            .div_heard_project {
                h2 {
                    span {}
                }
                .div_arrows {}
            }
            p {}
        }
        .fluid-2 {
            padding: 0rem 0rem 8rem 3rem;
            .section-content {
                .owl-carousel .owl-stage-outer .owl-stage {
                    .owl-item {
                        // width: 300px !important;
                    }
                }
            }
        }
        .container-2 {
            .section-content {
                .owl-carousel .owl-stage-outer .owl-stage {
                    height: 55vh;
                }
            }
        }
    }
}