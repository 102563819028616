.bh-container {
    width: 100vw;
    video {
        width: 100vw;
        // position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100vh;
        z-index: 1;
        position: absolute;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .bh-wrapper {
        position: relative;
        width: 100%;
        .bh-background {
            width: 100%;
            height: 100vh;
            object-fit: cover;
            object-position: center;
            filter: brightness(0.5);
            &.half-height{
                height: 35vh !important;
            }
        }
        .bh-video-btn-wrapper {
            position: absolute;
            top: 0%;
            color: var(--white);
            height: 100vh;
            width: 100%;
            left: 5rem;
            .button-video {
                background-color: #ffffff12;
                backdrop-filter: blur(2px);
                -webkit-backdrop-filter: blur(2px);
                color: var(--white) !important;
                border: 2px solid $bd-1;
                box-sizing: border-box;
                border-radius: 5px;
                padding: 25px;
                color: $color-1;
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        &::before {
            position: absolute;
            content: "";
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: #1c1c1c31 0% 0% no-repeat padding-box;
        }
        .text-container {
            z-index: 1;
            left: 150px;
            margin: 0 0 1.5rem 1.5rem;
            .title {
                font-size: 5.5rem;
            }
            .title-home {
                white-space: nowrap;
                @media (max-width: 476px) {
                    font-size: 4.5rem;
                    margin-bottom: 3.25rem;
                }
            }
            .blogTitle {
                font-size: 3.5rem;
            }
        }
    }
}

@media (max-width: 992px) {
    .bh-container {
        .bh-wrapper {
            .bh-video-btn-wrapper {
                left: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .bh-container {
        .text-container {
            left: 0!important;
            margin: 0!important;
            transform: translate(10%, -50%)!important;
        }
    }
}