:root {
    --primary-color: #BF9869;
    --secondary-color: #ECDBC6;
    --btn-primary-color: #d39d2a;
    --btn-secondary-color: #ac842d;
    --btn-primary-border: #d39d2a;
    --link-footer: #1caff0;
    --white: #ffffff;
    --links-color: #fffaf5;
    --aera-color: #99b6df;
    --campos-color: #3f5b42;
    --cerrito-color: #a361bf;
    --cerritomio-color: #ac2244;
    --cochabamba-color: #e54c29;
    --rivadavia-color: #61bfa6;
    --cantilo-color: #adbbff;
    --pareja-color: #3b57e5;
    --primary-bg: #BF9869;
    /* font-sizes */
    --navbar-links-size: 14px;
    --main-title-size: 40px;
    --main-title-our-devs: 50px;
    --main-text-size: 16px;
    --btn-text-size: 20px;
    --main-card-text-size: 23px;
    --font-size-1: 5.4rem;
    --font-size-2: 3.5rem;
    --font-size-3: 1.3rem;
    --font-size-4: 1rem;
    --font-size-5: 15rem;
    --font-size-6: 10rem;
    --font-size-61: 7.5rem;
    --font-size-7: 1.5rem;
    --font-size-10: 8rem;
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-18: 1.2rem;
    --font-size-20: 1.25rem;
    --font-size-21: 1.3rem;
    --font-size-23: 1.4rem;
    --font-size-24: 1.5rem;
    --font-size-25: 1.56rem;
    --font-size-30: 1.87rem;
    --font-size-38: 2rem;
    --font-size-40: 2.5rem;
    --font-size-50: 3rem;
    --font-size-60: 6vmin;
    --font-size-75: 2.5vw;
    --font-size-17: 17rem;
    //   font-family
    --first-font: Ogg-Regular;
    --second-font: BananaGrotesk-Regular;
}

//  colores Construfac
$color-0: #BF9869;
$color-1: #ECDBC6;
$color-2: #5A5B5F;
$color-3: #DDDDDE;
$color-4: #00A1FF;
$color-5: #ffffff;
$color-6: #000000;
$color-7: #dcdcdc;
$color-8: #acacac;
$color-9: #a0b0fe;
$color-10: #3d51b7;
$color-11: #f0ae7d;
$color-12: #FFA6A6;
$color-13: #F61E1E;
$color-14: #6F625D;
$color-15: #797979;
//  Background Construfac
$bg-0: #BF9869;
$bg-1: #ECDBC6;
$bg-2: #DDDDDE;
$bg-3: #5A5B5F;
$bg-4: #ffffff;
$bg-5: #1c1c1c;
$bg-6: #acacac;
$bg-7: #ffffff;
$bg-8: #000000;
$bg-9: #3b57e5;
$bg-10: #a0b0fe;
$bg-11: #3d51b7;
$bg-12: #ddddde;
$bg-13: #dcdcdc;
$bg-14: #acacac3a !important;
$bg-15: #ceae87;
$bg-16: #AEACAC;
$bg-17: #FFA6A6;
$bg-18: #F61E1E;
$bg-19: #6F625D;
$bg-20: #797979;
//  Bordes Construfac
$bd-0: #BF9869;
$bd-1: #ECDBC6;
$bd-2: #5A5B5F;
$bd-3: #DDDDDE;
$bd-4: #ffffff;
$bd-5: #3b57e5;
$bd-6: #a0b0fe;
$bd-7: #ffffff;
$bd-8: #3d51b7;
$bd-9: #F61E1E;
$bd-10: #FFA6A6;
$bd-11: #acacac;
$bd-12: #6F625D;
$bd-13: #797979;
//Fuentes
$font-0: "Ogg-Regular";
$md: 500;
$reg: normal;
$db: 600;
$font-1: "BananaGrotesk-Regular";
body {
    color: var(--white) !important;
}

.link-footer {
    color: var(--link-footer);
}

.text-primary-orange {
    color: var(--primary-color) !important;
}

.font-0 {
    font-family: $font-0 !important;
}

.font-1 {
    font-family: $font-1 !important;
}

.text-type-1 {
    font-weight: $md !important;
}

.text-type-2 {
    font-weight: $reg !important;
}

.text-type-3 {
    font-weight: $db !important;
}

.fs-1 {
    font-size: var(--font-size-1) !important;
}

.fs-2 {
    font-size: var(--font-size-2) !important;
}

.fs-3 {
    font-size: var(--font-size-3) !important;
}

.fs-4 {
    font-size: var(--font-size-4) !important;
}

.fs-5 {
    font-size: var(--font-size-5) !important;
}

.fs-6 {
  font-size: var(--font-size-6) !important;
}

.fs-7 {
    font-size: var(--font-size-7) !important;
}

.fs-10 {
    font-size: var(--font-size-10) !important;
}

@media (max-width: 1500px) {
    .fs-1 {
        font-size: var(--font-size-2) !important;
    }
    .fs-2 {
        font-size: var(--font-size-38) !important;
    }
    .fs-3 {
        font-size: var(--font-size-4) !important;
    }
    // .fs-4 {
    //   font-size: var(--font-size-5) !important;
    // }
    .fs-5 {
        font-size: var(--font-size-10) !important;
    }
    .fs-7 {
        font-size: var(--font-size-3) !important;
    }
}

@media (max-width: 992px) {
    .fs-1 {
        font-size: var(--font-size-2) !important;
    }
    .fs-2 {
        font-size: var(--font-size-40) !important;
    }
    .fs-3 {
        font-size: var(--font-size-18) !important;
    }
    // .fs-4 {
    //   font-size: var(--font-size-5) !important;
    // }
    .fs-5 {
        font-size: var(--font-size-10) !important;
    }
    .fs-50 {
        font-size: var(--font-size-38) !important;
    }
}

@media (max-width: 576px) { 
    .fs-6 {
        font-size: var(--font-size-61) !important;
      }
}

.fs-12 {
    font-size: var(--font-size-12);
}

.fs-13 {
    font-size: var(--font-size-13);
}

.fs-14 {
    font-size: var(--navbar-links-size) !important;
}

.fs-40 {
    font-size: 2.2rem;
}

.fs-50 {
    font-size: 2.6rem;
}

.fs-16 {
    font-size: 1rem;
}

.fs-17 {
    font-size: var(--font-size-17);
}

.fs-18 {
    font-size: var(--font-size-18);
}

.fs-20 {
    font-size: var(--font-size-20);
}

.fs-21 {
    font-size: var(--font-size-21);
}

.fs-23 {
    font-size: var(--font-size-23);
}

.fs-24 {
    font-size: var(--font-size-24);
}

.fs-25 {
    font-size: var(--font-size-25);
}

.fs-30 {
    font-size: var(--font-size-30);
}

.fs-38 {
    font-size: var(--font-size-38);
}

.fs-40 {
    font-size: var(--font-size-40);
}

.fs-50 {
    font-size: var(--font-size-50);
}

.fs-60 {
    font-size: var(--font-size-60);
}

.fs-75 {
    font-size: var(--font-size-75);
}

.aera {
    // Ejemplo con AERA
    .proyect-color {
        color: #99b6df !important;
        border-color: #99b6df !important;
    }
    .proyect-bg {
        background: #99b6df !important;
    }
}

.campos {
    // Ejemplo con CAMPOS
    .proyect-color {
        color: var(--campos-color) !important;
        border-color: var(--campos-color) !important;
    }
    .proyect-bg {
        background: var(--campos-color) !important;
    }
}

.cochabamba {
    .proyect-color {
        color: var(--cochabamba-color) !important;
        border-color: var(--cochabamba-color) !important;
    }
    .proyect-bg {
        background: var(--cochabamba-color) !important;
    }
}

.cerrito2142 {
    .proyect-color {
        color: var(--cerrito-color) !important;
        border-color: var(--cerrito-color) !important;
    }
    .proyect-bg {
        background: var(--cerrito-color) !important;
    }
}

.cerritomio {
    .proyect-color {
        color: var(--cerritomio-color) !important;
        border-color: var(--cerritomio-color) !important;
    }
    .proyect-bg {
        background: var(--cerritomio-color) !important;
    }
}

.rivadavia {
    .proyect-color {
        color: var(--rivadavia-color) !important;
        border-color: var(--rivadavia-color) !important;
    }
    .proyect-bg {
        background: var(--rivadavia-color) !important;
    }
}

.cantilo4645,
.cantilo-4645 {
    .proyect-color {
        color: var(--cantilo-color) !important;
        border-color: var(--cantilo-color) !important;
    }
    .proyect-bg {
        background: var(--cantilo-color) !important;
    }
}

.pareja3645 {
    .proyect-color {
        color: var(--pareja-color) !important;
        border-color: var(--pareja-color) !important;
    }
    .proyect-bg {
        background: var(--pareja-color) !important;
    }
}