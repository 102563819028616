.propiedad {
  .header__top {
    .button__container {
      a {
        .button__emprendimiento {
          border-radius: 5px;
        }
      }
    }
  }
  .main {
    height: calc(100vh - 50px) !important;
    min-height: unset;
    overflow: hidden;
    position: relative;
    margin-top: -65px;
    &::after {
      position: absolute;
      height: 100vh;
      width: 100%;
      content: "";
      bottom: 0;
      top: 0;
      background-color: #1f1f1f !important;
      opacity: 0.4;
      background-image: none;
      //   z-index: ;
    }
    .breadcrumb {
      margin-bottom: 3rem !important;
    }
    img.bg-main-prop {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: -1;
    }
    .content-main {
      height: calc(100vh - 51px) !important;
      color: #fff;
      //   padding-bottom: 4rem;
      z-index: 10;
      position: relative;
      .btn-square {
        padding: 0;
        width: 5rem;
        color: #fff;
        border-radius: 50%;
        // border: 1px solid #fff;
        background: transparent;
        height: 5rem;
        font-size: 1.5rem;
        // margin-right: 5rem;
        bottom: 5vh;
        right: 5%;
        // right: 11vw;
        line-height: 1;
        opacity: 1 !important;
        transition: 0.3s ease all;
        // color: transparent;
        background-color: $bg-3;
        text-decoration: none;
        .count {
          position: absolute;
          left: 45%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: var(--secondary-color);
          line-height: 1;
          font-size: 1.2rem;
          z-index: 0;
          font-weight: 400;
          opacity: 0;
        }
        i {
          color: #fff;
          margin: 0;
          transition: 0.3s ease all;
        }
        &:hover {
          background-color: $bg-15;
          .count {
            opacity: 1;
          }
          //   i {
          //     font-size: 0.95rem;
          //     opacity: 1;
          //     transform: translateX(30px);
          //     color: var(--secondary-color);
          //   }
        }
        &::before {
          padding-top: 4px;
          font-size: 2.3rem;
        }
      }
      .div_dress_title {
        margin-bottom: 5rem;
        align-self: center;
        h2 {
          letter-spacing: 0;
        }
        .div_bottom_arrow {
          //   transform: rotate(90deg);
          margin-top: 3rem;
          z-index: 1;
          position: relative;
          a {
            border: 1px solid white;
            border-radius: 49%;
            padding: 2.2rem;
            position: absolute;
            transition: 1s all;
            span {
              position: absolute;
              font-size: 1rem;
              color: white;
              top: 25px;
              left: 26px;
              transform: rotate(90deg);
              transition: 1s all;
            }
            &:hover {
              border-color: $bd-5;
              span {
                top: 35px;
                color: $color-8;
              }
            }
          }
        }
      }
      .btn {
        padding: 0;
        width: 5.5rem;
        height: 5.5rem;
        font-size: 1.5rem;
        img {
          width: 100%;
          opacity: 0;
          height: calc(100vh - 114px) !important;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .propiedad {
    .main {
      height: 90vh;
      background-attachment: unset;
      position: relative;
      min-height: 90vh;
      &::before {
        opacity: 0.6;
      }
      &::after {
        // height: 90vh;
        background-color: #1f1f1f;
        opacity: 0.4;
        background-image: none;
      }
      .content-main {
        padding-bottom: 0;
        height: 90vh;
        .div_dress_title {
          margin-bottom: 9rem;
          h2 {
            font-size: 2rem;
            font-weight: 300;
            line-height: 1.1;
            padding-left: 0;
            max-width: 90%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            margin-bottom: 0 !important;
            .location {
              font-size: 1rem;
            }
          }
        }
        .btn-square {
          // width: 4rem;
          position: absolute !important;
          left: 1rem;
          bottom: 2vh;
          // height: 4rem;
          font-size: 1.5rem;
        }
      }
      .btn {
        width: 4rem;
        height: 4rem;
        position: absolute !important;
        left: calc(0%);
        bottom: 5vh;
        font-size: 1rem;
      }
    }
  }
  // Whatsapp fixed icon bottom align with picture icon
  .fixed-bar {
    bottom: 2vh;
  }
}
