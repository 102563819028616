.brand {
  background: $bg-0;
  //   border-radius: 50%;
  //   position: absolute;
  //   top: -264px;
  //   left: -187px;
  width: 260px;
  height: 170px;
  z-index: 7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brand__image {
  //   position: absolute;
  //   top: 65%;
  //   left: 40%;
  width: 196px;
  height: 35px;
}

.sidebar {
  width: 140px;
  height: 100vh;
  //   background-color: transparent;
  // background: var(--unnamed-color-1c1c1c) 0% 0% no-repeat padding-box;
  display: none;
  z-index: 999999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  background: $bg-4;
}

.vertical__title__container {
  height: 20rem;
}

.sidebar__vertical {
  color: var(--primary-color);
  font-size: 1.5vh;
  width: 140px;
  justify-content: center;
  text-align: center;
  margin-bottom: 2.5vh;
  //   height: 100%;
}

.vertical__title {
  text-transform: uppercase;
  letter-spacing: 0.2rem;
  white-space: nowrap;
  transform: rotate(-90deg);
}

.vertical__social__container {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.vertical__line {
  margin-bottom: 2rem;
  hr {
    opacity: 1;
    width: 1px;
    height: 150px;
    margin: 0 auto;
    background-color: $bg-3;
  }
}

.vertical__social__item {
  // font-size: 1.5vh;
  // line-height: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;
  background: $bg-20;
  padding: 2px 8px;
  border-radius: 50%;
  a {
    // font-size: 1.1rem;
    font-size: 12px;
    text-decoration: none;
    color: $color-5;
    transition: all 0.3s;
    z-index: 2;
    &:hover {
      color: var(--white);
    }
    // &::before {
    //   content: "";
    //   content: "";
    //   position: absolute;
    //   width: 2.5rem;
    //   height: 2.5rem;
    //   background: $bg-5;
    //   border-radius: 50%;
    //   z-index: -1;
    //   top: 3px;
    //   left: -7px;
    // }
    i {
      line-height: 2;
    }
  }
}

@media screen and (max-height: 880px) {
  .sidebar__vertical .h-50 {
    height: 40% !important;
  }
}

@media screen and (max-height: 700px) {
  .vertical__title__container {
    visibility: hidden !important;
  }
}
