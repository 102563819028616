.buttonAnimation {
  height: 5rem;
  background-color: $bg-1;
  border-radius: 0;
  transition: all 0.3s;
  outline: unset;
  border: unset;
  color: $color-0;
  padding: 0 4.5rem 0 3rem;
  letter-spacing: 1.5px;
  border: 1px solid $bd-0;
  position: relative;
  &:hover {
    background-color: $bg-7;
    i {
      right: 1.6rem;
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  i {
    font-size: 1.5rem;
    position: absolute;
    right: 2rem;
    // top: 1.7rem;
  }
}

.ButtonAnimationPrimary {
  margin: 0 1rem;
}

@media (max-width: 992px) {
  .buttonAnimation {
    // padding: 0 2rem;
    // height: 3.5rem;
    font-size: 0.8rem;
    margin-top: 1rem;
  }
}
