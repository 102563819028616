form {
  .row-field {
    .div_input {
      .form-control {
        margin-top: 1.5rem;
        position: relative;
        padding: 1rem 2rem !important;
        border: unset !important;
        padding-bottom: 15px !important;
        border-bottom: 1px solid $bd-1 !important;
        background-color: unset !important;
        color: $color-1 !important;
        font-family: $font-1;
        font-size: 18px !important;
        border-radius: 0;
        &::placeholder {
          color: $color-1 !important;
        }
        &:hover {
          box-shadow: none !important;
          border: unset;
          &::placeholder {
            color: $color-1;
          }
        }

        &:focus {
          box-shadow: none !important;
          border: unset;
          &::placeholder {
            color: $color-1 !important;
          }
        }
      }
      textarea {
        resize: none !important;
        overflow: hidden !important;
        margin-bottom: 1.5rem;
        padding: 0.375rem 0 !important;
        font-size: 18 !important;
        min-height: 180px;
      }
    }
  }
}
