.navbar {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
  // display: none;
  height: 170px;
  .container-fluid {
    height: 46px;
    margin-right: 5%;
  }
  nav {
    height: 46px;
  }
  ul li:first-child {
    margin-left: 0;
  }
  ul {
    height: 46px;
    li:first-child.hide-lg-item {
      @media (max-width: 1350px) {
        display: none;
      }
    }
  }
  ul li:last-child {
    margin-right: 0;
  }
  ul li {
    box-sizing: border-box;
    margin: 0 0.8rem;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      box-sizing: border-box;
      font-family: $font-1;
      letter-spacing: 4px;
      padding: .8vw 1.3vw;
      height: 46px;
      border: 1px solid transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: 992px) and (max-width: 1199px) {
        font-size: 0.9vw;
      }
      @media (min-width: 1200px) {
        font-size: 0.7vw;
      }
    }
    &.active {
      // background-color: $bg-0 !important;
      background-color: #ffffff12;
      backdrop-filter: blur(9px);
      -webkit-backdrop-filter: blur(9px);
      color: var(--white) !important;
      border: 1px solid $bd-4;
      box-sizing: border-box;
    }
  }
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 259px;
    background: transparent
      linear-gradient(
        180deg,
        #000000 0%,
        #000000ea 35%,
        #000000b3 65%,
        #00000000 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.8;
  }
}

.nav-link {
  letter-spacing: 0.2rem;
  transition: all 0.3s;
  text-transform: uppercase;
  color: var(--white) !important;
  background-color: #00000000 !important;
  border: 1px solid transparent;
  &:not(.active):hover {
    // text-decoration: underline !important;
    background-color: #ffffff12;
    backdrop-filter: blur(9px);
    -webkit-backdrop-filter: blur(9px);
    color: var(--white) !important;
    border: 1px solid $bd-4;
    box-sizing: border-box;
  }
}
