.div_ubicacion {
  margin-top: 5rem;
  text-align: center;
  .container-fluid {
    .contact-container {
      color: $color-2;
        &::before {
          top: 45%;
          left: 10%;
          z-index: 1;
          opacity: 0.05;
          color: $color-2;
          font-size: 16rem;
          position: absolute;
          white-space: nowrap;
          content: "Mapa";
          transform: translate(-50%, -50%);
      }
      h2 {
        color: $color-13;
      }
    }
  }
}
