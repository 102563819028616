.concept-container {
    color: $color-2;
    padding: 10rem 0rem 11rem 0rem;
    h2.text-center {
        z-index: 2;
        font-size: 6rem;
        margin: 2rem 0 6rem 0;
    }
    .text-container {
        h3.subtitle {
            margin: 2rem 0;
            color: $color-0;
            font-size: 2.5rem;
        }
        p.font-1 {
            color: $color-8;
        }
        h3.subtitle,
        p.font-1 {
            max-width: 80%;
        }
    }
    &::before {
        top: 12%;
        @media (max-width: 991px) {
            top: 9%;
        }
        left: 50%;
        z-index: 1;
        opacity: 0.05;
        color: $color-2;
        font-size: 16rem;
        position: absolute;
        white-space: nowrap;
        content: "New Urban Lifestyle";
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 992px) {
    .concept-container {
        .text-container {
            padding: 0 3rem;
            h3.subtitle,
            p.font-1 {
                max-width: 100%;
            }
        }
        .img-container {
            .img-fluid {
                max-width: 80%;
                margin: 5rem 0;
            }
        }
    }
}