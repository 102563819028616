.mp-map {
    padding: 7rem 0;
    .container-fluid {
        .logo-animation-container {
            top: -3rem;
            left: 2%;
            .logo-circle {
                right: 10%;
                z-index: 1;
                width: 20rem;
                opacity: 0.3;
                bottom: -6rem;
                @media (max-width: 992px) {
                    width: 10rem;
                }
            }
            .logo-center-letter {
                opacity: 0.2;
                width: 10rem;
                @media (max-width: 992px) {
                    width: 4rem;
                }
            }
        }
        .list-container {
            max-width: 70%;
            margin-top: 5rem;
            .order-list {
                margin: 0 auto;
                padding: 1.5rem;
                font-family: $font-1;
                border: 1px solid $bd-0;
                display: grid;
                grid-auto-flow: column;
                grid-gap: 1px;
                grid-template-columns: repeat(4, 1fr); 
                grid-template-rows: repeat(3, auto);
                @media (max-width: 1200px) {
                    grid-template-columns: repeat(3, 1fr); 
                    grid-template-rows: repeat(4, auto);
                }
                @media (max-width: 992px) {
                    grid-template-columns: repeat(2, 1fr); 
                    grid-template-rows: repeat(6, auto);
                }
                @media (max-width: 576px) {
                    grid-template-columns: 1fr; 
                    grid-template-rows: repeat(11, auto);
                }
                .order-list-item {
                    color: $color-3;
                    // width: 190px;
                    margin: 1rem 0;
                    padding-left: 1.5rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                    .order-num {
                        font-size: .97rem;
                        width: 1.5rem;
                        height: 1.5rem;
                        color: $color-5;
                        text-align: center;
                        border-radius: 100%;
                        margin-right: .4rem;
                        background-color: $bg-0;
                    }
                    .list-item {
                        font-size: 1.2rem;
                    }
                    &:first-child {
                        display: block;
                        max-width: 180px;
                    }
                }
            }
        }
    }
}

@media (max-width: 840px) {
    .mp-map {
        .list-container {
            .order-list {
                grid-template-columns: auto auto!important;
            }
        }
    }
}

@media (max-width: 576px) {
    .mp-map {
        .list-container {
            .order-list {
                grid-template-columns: auto!important;
            }
        }
    }
}