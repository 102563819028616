.header {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.header__top {
  background-color: #282828;
  height: 50px;
  z-index: 9999;
}

.header__emprendimiento__title {
  color: #848484;
  display: block;
  width: inherit;
  text-align: center;
}

.button__container {
  position: absolute;
  right: 0;
  a {
    // font-family: "sourceSansPro-regular" !important;
    text-decoration: none !important;
  }
}

.button__emprendimiento {
  padding: 0.7rem 1rem;
  height: 34px;
  color: $color-12;
  background-color: $bg-18;
  border: unset;
  outline: unset;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    color: $color-13;
    background-color: $bg-17;
  }
}

.main__second__header__emprendimiento {
  background-color: #282828 !important;
  opacity: 0.84;
  height: 64px;
  position: relative;
  z-index: 99;
  //   img {
  //     height: 40px;
  //   }
  .title_development {
    color: $color-8;
  }
  li {
    a {
      color: #fff;
      letter-spacing: 3px;
      text-decoration: none;
      font-size: 1rem;
      font-family: $font-1;
      &:hover {
        color: $color-8;
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 992px) {
  .header__top {
    padding: 0.2rem 0;
    height: unset;
    .header__emprendimiento__title {
      font-size: 0.8rem;
      width: auto;
    }
  }
  .main__second__header__emprendimiento {
    nav {
      display: none !important;
    }
  }
  .button__container {
    position: relative;
    margin: 0.5rem 0;
    margin-left: auto;
    text-align: center;
    display: inline-block;
    width: auto;
    font-size: 0.8rem;
  }
}
