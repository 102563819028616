.novedad {
  .layout {
    .content {
      .div_section_form {
        .container-fluid {
          .div_news_container {
            border-color: $bd-3;
            .div_vertical {
              p {
                color: $color-6;
                background-color: $bg-4;
              }
            }
            .row {
              .div_title {
                border-color: $bd-3;
              }
              .div_first {
                h3 {
                }
                p {
                  color: $color-0;
                }
              }
            }
          }
        }
      }
    }
  }
}
