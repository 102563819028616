footer {
    background: $bg-3;
    width: 100vw;
    margin: auto;
    padding: 2rem 0rem 5rem 0rem;
    z-index: 999;
    position: relative;
    .copy {
        a:hover {
            text-decoration: none !important;
        }
        .mediacore-link {
            color: #fff !important;
        }
    }
    .div_logo {
        position: absolute;
        padding: 2rem;
        .brand__image {
            width: 285px;
            height: 50px;
        }
    }
    .section__five__arrow__top {
        position: absolute;
        right: 0;
        top: 2.5rem;
        cursor: pointer;
        z-index: 9999999;
        transform: rotate(270deg);
        text-decoration: none;
        i {
            font-size: 4rem;
            color: $color-0;
        }
        &:hover {
            top: 1.5rem;
            transition: 1.5s all;
            i {
                color: $color-1;
            }
        }
    }
    .navbar {
        position: relative !important;
        .container {
            // max-width: 1140px !important;
        }
        &::before {
            display: none;
        }
        @media (min-width: 992px) and (max-width: 1700px) {
            height: 220px;
            padding-bottom: 0!important;
            padding-top: 7rem!important;
            nav>ul.nav {
                justify-content: center;
            }
        }
        nav>ul.nav>li.nav-item>a.nav-link {
            font-size: 1rem;
        }
    }
    .first_div_footer {
        h4 {}
        .div_members {
            width: fit-content;
            background: $bg-16;
            border: 1px solid $bd-4;
            // border-radius: 5px;
            transition: 0.5s all;
            cursor: pointer;
            &:hover{
                text-decoration: none !important;
            }
            .members_text {
                border-right: 1px solid $color-5;
                padding: 1rem 2rem;
                text-decoration: none !important;
                p {
                    text-decoration: none !important;
                    margin: 0;
                    color: $color-5;
                }
            }
            .members_icon {
                align-self: center;
                padding: 1rem 2rem;
                text-decoration: none !important;
                i {
                    text-decoration: none !important;
                    color: $color-5;
                    font-size: 2rem;
                }
            }
            &:hover {
                background: $bg-3;
            }
        }
    }
    .second_div_footer {
        span {
            font-size: var(--font-size-3);
            display: flex;
            color: $color-0;
            margin-bottom: 1rem;
            p {
                font-weight: bold;
                margin-bottom: 0;
            }
            a {
                color: $color-0;
                text-decoration: none;
                text-transform: none;
                transition: 1s all;
                &:hover {
                    color: $color-4;
                    transition: 1s all;
                }
            }
        }
    }
    .third_div_footer {
        .content-newsletter {
            position: relative;
            .newsletter-transparent {
                opacity: 0;
            }
            &:hover {
                form {
                    input {
                        // background-color: $bg-1 !important;
                        z-index: 3;
                    }
                    button {
                        .icon-arrow-solid {
                            color: $color-1;
                            font-size: 2rem;
                        }
                        &:hover {
                            // background: $bg-1;
                        }
                    }
                    // .custom_placeholder {
                    //   z-index: -1;
                    //   transition: 0.3s ease all;
                    // }
                }
            }
            & form {
                padding: 1.3rem 2rem;
                background: $color-2;
                border-bottom: 1px solid $color-3;
                border-radius: 0;
                .container-input {
                    @media (max-width: 1200px) {
                        justify-content: space-around !important;
                    }
                }
                input[type="text"] {
                    width: 100%;
                    font-size: 1.5rem;
                    border: none;
                    background-color: transparent;
                    color: $color-3;
                    font-family: $font-1;
                    letter-spacing: 1.5px;
                    &::placeholder {
                        color: $color-3;
                        font-size: 1.5rem;
                    }
                    &:focus {
                        // background: $bg-1;
                    }
                    @media (max-width: 1200px) {
                        width: 55%;
                    }
                }
                .custom_placeholder {
                    z-index: 2;
                    font-size: 1rem;
                    color: $color-3;
                    line-height: 2;
                    transition: 0.3s ease all;
                    font-family: $font-1;
                    letter-spacing: 1.5px;
                }
                button {
                    background: transparent;
                    border: none;
                    // transform: rotate(90deg);
                    .icon-arrow-stick-light {
                        color: $color-3;
                        font-size: 2rem;
                    }
                    @media (max-width: 1200px) {
                        position: static;
                        bottom: 0;
                        left: 0;
                        margin: 1.5rem -1rem -1rem -1rem;
                        align-self: flex-start;
                        // margin-bottom: 0;
                        // transform: none;
                        // width: calc(100% + 2rem);
                    }
                }
            }
        }
        .content-newsletter {
            position: relative;
            // border-bottom: 1px solid $color-1;
            .newsletter-transparent {
                opacity: 0;
            }
            &:hover {
                // border-bottom: 1px solid black;
                form {
                    background-color: $bg-14;
                    border: 1px solid $bg-2;
                    input {
                        z-index: 3;
                    }
                    button {
                        .icon-arrow-long {
                            color: black;
                        }
                        &:hover {
                            background: $bg-6;
                        }
                    }
                    .custom_placeholder {
                        z-index: -1;
                        transition: 0.3s ease all;
                        color: transparent;
                    }
                }
            }
            & form {
                // background-color: transparent;
                .container-input {
                    @media (max-width: 1024px) {
                        justify-content: space-around !important;
                    }
                }
                input[type="text"] {
                    width: 100%;
                    font-size: 2rem;
                    // padding: 0 1.6rem;
                    border: none;
                    background-color: transparent;
                    color: $color-3;
                    letter-spacing: 1.5px;
                    font-size: 1.5rem;
                    &::placeholder {
                        color: $color-1;
                        font-size: 1.5rem;
                    }
                    &:focus {
                        background: transparent;
                    }
                    @media (max-width: 1024px) {
                        width: 55%;
                    }
                }
                .custom_placeholder {
                    z-index: 1;
                    top: 8px;
                    font-size: 1.5rem;
                    color: $color-3;
                    line-height: 1.3;
                    transition: 0.3s ease all;
                    letter-spacing: 1.5px;
                    font-size: 2rem;
                }
                button {
                    background: transparent;
                    border: none;
                    .icon-arrow-solid {
                        transition: 0.3s ease all;
                        color: $color-1;
                        font-size: 1rem;
                        padding: 1rem;
                    }
                    @media (max-width: 1024px) {
                        position: static;
                        bottom: 0;
                        left: 0;
                        margin: 1.5rem -1rem -1rem -1rem;
                        align-self: flex-start;
                        // margin-bottom: 0;
                        // transform: none;
                        // width: calc(100% + 2rem);
                    }
                }
            }
        }
    }
    .copy {
        font-size: 16px;
        color: #ffffff;
        font-weight: 300;
        a {
            color: #00a1ff !important;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .footer-captcha {
        margin-left: 3.5rem;
    }
    .footer-link {
        color: #ffffff;
        font-size: 2rem;
        // font-weight: 600;
        &:hover {
            color: $color-3;
        }
        &.no-hover {
            &:hover {
                color: #ffffff;
            }
        }
    }
    .footer-wrapper {
        font-family: $font-0;
    }
}

@media (max-width: 1550px) {
    footer {
        .copy .mediacore-link {}
        .div_logo {
            .brand__image {}
        }
        .section__five__arrow__top {
            i {}
        }
        .navbar {
            .container {
                // max-width: 940px !important;
            }
            &::before {}
        }
        .first_div_footer {
            h4 {}
            .div_members {}
        }
        .icon {}
        .second_div_footer {
            h4 {}
            .iconos-redes-footer {
                & a {
                    &:hover {
                        .icon-ig,
                        .icon-youtube,
                        .icon-facebook {}
                    }
                }
            }
        }
        .third_div_footer {
            .content-newsletter {
                .newsletter-transparent {
                    opacity: 0;
                }
                &:hover {
                    form {
                        input {}
                        button {
                            .icon-arrow-long {}
                            &:hover {}
                        }
                        .custom_placeholder {}
                    }
                }
                & form {
                    .container-input {
                        @media (max-width: 1200px) {}
                    }
                    input[type="text"] {
                        &::placeholder {}
                        &:focus {}
                        @media (max-width: 1200px) {}
                    }
                    .custom_placeholder {}
                    button {
                        .icon-arrow-long {}
                        @media (max-width: 1200px) {}
                    }
                }
            }
        }
        .copy {
            a {
                &:hover {}
            }
        }
        .footer-captcha {}
        .footer-link {
            &:hover {}
            &.no-hover {
                &:hover {}
            }
        }
        .footer-wrapper {}
    }
}

@media (max-width: 1260px) {
    footer {
        .copy .mediacore-link {}
        .div_logo {
            .brand__image {}
        }
        .section__five__arrow__top {
            i {}
        }
        .navbar {
            .container {
                // max-width: 725px !important;
            }
            &::before {}
        }
        .first_div_footer {
            h4 {}
            .div_members {}
        }
        .icon {}
        .second_div_footer {
            h4 {}
            .iconos-redes-footer {
                & a {
                    &:hover {
                        .icon-ig,
                        .icon-youtube,
                        .icon-facebook {}
                    }
                }
            }
        }
        .third_div_footer {
            .content-newsletter {
                .newsletter-transparent {
                    opacity: 0;
                }
                &:hover {
                    form {
                        input {}
                        button {
                            .icon-arrow-long {}
                            &:hover {}
                        }
                        .custom_placeholder {}
                    }
                }
                & form {
                    .container-input {
                        @media (max-width: 1200px) {}
                    }
                    input[type="text"] {
                        &::placeholder {}
                        &:focus {}
                        @media (max-width: 1200px) {}
                    }
                    .custom_placeholder {}
                    button {
                        .icon-arrow-long {}
                        @media (max-width: 1200px) {}
                    }
                }
            }
        }
        .copy {
            a {
                &:hover {}
            }
        }
        .footer-captcha {}
        .footer-link {
            &:hover {}
            &.no-hover {
                &:hover {}
            }
        }
        .footer-wrapper {}
    }
}

@media (max-width: 992px) {
    footer {
        .copy .mediacore-link {}
        .div_logo {
            .brand__image {
                width: 145px;
                height: 25px;
            }
        }
        .section__five__arrow__top {
            right: 3rem;
            top: 1.5rem;
            i {}
        }
        .navbar {
            .container {
                // max-width: 940px !important;
            }
            &::before {}
        }
        .footer-wrapper {
            margin-top: 10rem;
            .first_div_footer {
                h4 {}
                .div_members {
                    margin: auto;
                    .members_text {
                        padding: 0.5rem 2.5rem;
                        p {}
                    }
                    .members_icon {
                        padding: 0.5rem 2rem;
                        i {}
                    }
                    &:hover {}
                }
            }
            .icon {}
            .second_div_footer {
                span {
                    justify-content: center;
                    p {}
                }
            }
            .third_div_footer {
                .content-newsletter {
                    .newsletter-transparent {
                        opacity: 0;
                    }
                    &:hover {
                        form {
                            input {}
                            button {
                                .icon-arrow-long {}
                                &:hover {}
                            }
                            .custom_placeholder {}
                        }
                    }
                    & form {
                        input[type="text"] {
                            &::placeholder {}
                            &:focus {}
                            width: 90%;
                        }
                        .custom_placeholder {}
                        button {
                            .icon-arrow-long {}
                            position: static;
                            bottom: 0;
                            left: 0;
                            margin: 0.3rem -1rem -1rem 0;
                            // margin: 0.3rem -0.5rem -1rem 0;
                            align-self: flex-start;
                        }
                    }
                }
                .footer-captcha {
                    div {
                        div {
                            div {
                                div {
                                    iframe {
                                        html {
                                            body {
                                                .rc-anchor-normal {
                                                    width: auto !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .copy {
                font-size: 1rem;
                a {
                    &:hover {}
                }
            }
            .footer-captcha {
                margin-top: 3rem;
            }
            .footer-link {
                &:hover {}
                &.no-hover {
                    &:hover {}
                }
            }
        }
    }
}