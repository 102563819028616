.Inicio {
    position: relative;
    .main {
        height: calc(100vh);
        position: relative;
        z-index: 1;
        width: 100%;
        background-color: var(--secondary-color);
        .owl-carousel {
            height: 100vh;
            .owl-item {
                width: 100%;
                margin: 0;
                .art {
                    .text-content {
                        z-index: 2;
                        text-align: left;
                        height: 100vh;
                        padding-top: 10vh;
                        color: $color-1;
                        h2 {
                            // font-family: 'montserrat bold', sans-serif;
                            letter-spacing: 1px;
                            color: $color-1;
                        }
                        h4 {
                            margin: 1.4rem 0 1.4rem 0;
                            max-width: 55vw;
                            letter-spacing: 1px;
                            color: $color-1;
                        }
                        p {
                            letter-spacing: 1.5px;
                            line-height: 1;
                            max-width: 55vw;
                            margin-bottom: 4rem;
                            color: $color-1;
                            // font-family: 'montserrat regular', sans-serif
                        }
                        a {
                            width: fit-content;
                            border-radius: 5px;
                        }
                    }
                    img,
                    .opacity {
                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        height: 100vh;
                        object-fit: cover;
                        -webkit-filter: grayscale(100%);
                        filter: grayscale(100%);
                    }
                    .opacity {
                        background-color: #333;
                        opacity: 0.5;
                        z-index: 1;
                    }
                }
            }
            .owl-dots {
                position: absolute;
                top: 90%;
                left: 50%;
                z-index: 99999;
                display: flex;
                top: 90%;
                transform: translate(-50%, -50%);
                .owl-dot span {
                    width: 1rem;
                    height: 1rem;
                    margin: 5px 7px;
                    background: #ffffff;
                    opacity: 0.4;
                    display: block;
                    -webkit-backface-visibility: visible;
                    transition: opacity 0.2s ease;
                    border-radius: 30px;
                }
            }
            .owl-dots .owl-dot.active span,
            .owl-theme .owl-dots .owl-dot:hover span {
                background: $bg-7;
                opacity: 1;
            }
        }
        .line-degrade {
            width: 40vw;
            position: absolute;
            bottom: 20vh;
            height: 1.1rem;
            z-index: 3;
            display: block;
        }
        #content-main {
            height: 80vh;
            position: relative;
            z-index: 3;
            padding-top: 8.3rem;
            h1 {
                font-size: 5vw;
                color: #fff;
                line-height: 0.9;
                font-weight: 200;
            }
            p {
                font-size: 1.6rem;
                color: #fff;
                font-weight: 200;
                margin-top: 2rem;
            }
        }
    }
}

@media (max-width: 1780px) {
    .Inicio {
        .main {
            .owl-carousel {
                .owl-item {
                    .art {
                        .text-content {
                            width: 80%;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .Inicio {
        .main {
            .owl-carousel {
                .owl-item {
                    .art {
                        .text-content {
                            width: 70%;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1100px) {
    .Inicio {
        .main {
            .owl-carousel {
                .owl-item {
                    .art {
                        .text-content {
                            width: 65%;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .Inicio {
        &::before {
            display: none;
        }
        .main {
            min-height: 100vh;
            max-height: 100vh;
            width: 100% !important;
            .owl-carousel {
                .owl-item {
                    .art {
                        .text-content {
                            text-align: left;
                            padding-top: 10vh;
                            width: 85%;
                            h2 {
                                font-size: 2rem;
                                text-align: left !important;
                                width: 100%;
                            }
                            h4 {
                                margin: 1rem 0 1rem 0;
                                font-size: 1rem;
                                text-align: left;
                                margin: 1rem auto;
                            }
                            p {
                                text-align: left;
                                margin-bottom: 2rem;
                                line-height: 1.3;
                                font-size: 1.5rem !important;
                                letter-spacing: 0;
                                max-width: 80vw;
                            }
                            a {
                                text-align: left;
                                margin-left: auto;
                                margin-top: 10vh;
                                margin-right: auto;
                            }
                        }
                        img,
                        .opacity {
                            position: absolute;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            right: 0;
                            height: 100vh;
                            object-fit: cover;
                        }
                        .opacity {
                            background-color: #333;
                            opacity: 0.5;
                            z-index: 1;
                        }
                    }
                }
            }
            .arrow__container {
                img {
                    width: 1rem;
                }
            }
        }
    }
}