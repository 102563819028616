.nosotros {
  .content {
    .div_trayectoria {
      position: relative;
      padding: 7rem 0rem 0rem 0rem;
      background-color: $bg-7;
      display: flex;
      align-items: center;

      .container-fluid.text-img-wrap {
        margin-bottom: 10rem;
        .container-header {
          .text-container {
            max-width: 100%;
            h3 {
              color: $color-2;
            }
            p {
              color: $color-14;
            }
          }
          .img-fluid {
            max-width: 45%;
          }
        }
      }

      .container-fluid {
        .map-container {
          h2 {
            color: $color-14;
          }
          h3 {
            color: $color-2;
          }
          &::before {
            top: 45%;
            left: 10%;
            z-index: 1;
            opacity: 0.2;
            color: $color-3;
            font-size: 13rem;
            position: absolute;
            white-space: nowrap;
            content: "Mapa";
            transform: translate(-50%, -50%);
          }
        }
      }

      .container-fluid {
        // margin-right: inherit;
        // max-width: 90%;
        // border-left: 2px solid #1c1c1c;
        .div_trayectoria__title {
          position: absolute;
          top: 8rem;
          left: -2rem;
          h2 {
            color: $color-5;
            opacity: 0.14;
            width: max-content;
          }
        }

        .div_trayectoria_container {
          padding: 0 10rem 10rem 10rem;
          border-left: 2px solid $bd-0;
          position: relative;
          margin-top: 3rem;
          .div_vertical {
            p {
              transform: rotate(180deg);
              margin: 0;
              position: absolute;
              left: -20px;
              writing-mode: tb-rl;
              color: $color-0;
              top: 0rem;
              background-color: $bg-7;
              padding-top: 3rem;
              padding-bottom: 2rem;
            }
          }
          .div_trayectoria_content {
            padding-bottom: 7rem;
            h2 {
              color: $color-6;
              min-width: max-content;
            }
            .line {
              width: 150px;
              height: 3px;
              background-color: $bg-0;
              align-self: center;
            }
          }
          .first_col_trayectoria {
            .img_trayectoria_1 {
              width: 100%;
              box-shadow: 0px 30px 60px #00000038;
              border-radius: 6px;
            }
            .primer_parrafo {
              margin-top: 8rem;
            }

            p {
              color: $color-5;
            }
            ul {
              color: $color-5;
            }
            .ver_mas {
              color: $color-0;
              &:hover {
                color: $color-4;
              }
            }
          }
          .second_col_trayectoria {
            h3 {
              color: $color-11;
            }
            .img_trayectoria_2 {
              width: 85%;
              box-shadow: 0px 30px 60px #00000038;
              border-radius: 6px;
              align-self: end;
              margin-top: 12rem;
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      .div_trayectoria {
        .div_trayectoria__title {
          top: 11rem !important;
          h2 {
            font-size: 5rem;
          }
        }
        .container-fluid {
          .div_trayectoria_container {
            padding: 0 0rem 11rem 2rem;
            .div_vertical {
              p {
                left: -14px;
              }
            }
            .div_trayectoria_content {
              h2 {
                min-width: auto;
                &:nth-last-child(1) {
                  font-size: 3rem !important;
                }
              }
            }
            .first_col_trayectoria {
              .img_trayectoria_1 {
              }
              .primer_parrafo {
              }

              p {
                .ver_mas {
                }
              }
              ul {
              }
            }
            .second_col_trayectoria {
              h3 {
              }
              .img_trayectoria_2 {
                margin-top: 7rem;
                align-self: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .container-fluid.text-img-wrap {
    .container-header {
      .text-container {
        max-width: 50%;
      }
    }
  }
}