@font-face {
    font-family: "Ogg-Regular";
    src: url("../../fonts/gv-fonts/Ogg-Regular.eot");
    src: url("../../fonts/gv-fonts/Ogg-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/gv-fonts/Ogg-Regular.woff2") format("woff2"),
        url("../../fonts/gv-fonts/Ogg-Regular.woff") format("woff"),
        url("../../fonts/gv-fonts/Ogg-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BananaGrotesk-Light";
    src: url("../../fonts/gv-fonts/BananaGrotesk-Light.eot");
    src: url("../../fonts/gv-fonts/BananaGrotesk-Light.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/gv-fonts/BananaGrotesk-Light.woff2") format("woff2"),
        url("../../fonts/gv-fonts/BananaGrotesk-Light.woff") format("woff"),
        url("../../fonts/gv-fonts/BananaGrotesk-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BananaGrotesk-Semibold";
    src: url("../../fonts/gv-fonts/BananaGrotesk-Semibold.eot");
    src: url("../../fonts/gv-fonts/BananaGrotesk-Semibold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/gv-fonts/BananaGrotesk-Semibold.woff2") format("woff2"),
        url("../../fonts/gv-fonts/BananaGrotesk-Semibold.woff") format("woff"),
        url("../../fonts/gv-fonts/BananaGrotesk-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "BananaGrotesk-Regular";
    src: url("../../fonts/gv-fonts/BananaGrotesk-Regular.eot");
    src: url("../../fonts/gv-fonts/BananaGrotesk-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/gv-fonts/BananaGrotesk-Regular.woff2") format("woff2"),
        url("../../fonts/gv-fonts/BananaGrotesk-Regular.woff") format("woff"),
        url("../../fonts/gv-fonts/BananaGrotesk-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'icomoon';
    src:  url('../../fonts/gv-fonts/icomoon.eot?7fjln0');
    src:  url('../../fonts/gv-fonts/icomoon.eot?7fjln0#iefix') format('embedded-opentype'),
      url('../../fonts/gv-fonts/icomoon.ttf?7fjln0') format('truetype'),
      url('../../fonts/gv-fonts/icomoon.woff?7fjln0') format('woff'),
      url('../../fonts/gv-fonts/icomoon.svg?7fjln0#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
[class^="icon-"], 
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
.icon-age-light:before {
    content: "\e900";
}
.icon-age-solid:before {
    content: "\e901";
}
.icon-age:before {
    content: "\e902";
}
.icon-aircond-light:before {
    content: "\e903";
}
.icon-aircond-solid:before {
    content: "\e904";
}
.icon-aircond:before {
    content: "\e905";
}
.icon-amenities-light:before {
    content: "\e906";
}
.icon-amenities-solid:before {
    content: "\e907";
}
.icon-amenities:before {
    content: "\e908";
}
.icon-arrow-light:before {
    content: "\e909";
}
.icon-arrow-solid:before {
    content: "\e90a";
}
.icon-arrow-stick-light:before {
    content: "\e90b";
}
.icon-arrow-stick-solid:before {
    content: "\e90c";
}
.icon-arrow-stick:before {
    content: "\e90d";
}
.icon-arrow:before {
    content: "\e90e";
}
.icon-bathroom-light:before {
    content: "\e90f";
}
.icon-bathroom-solid:before {
    content: "\e910";
}
.icon-bathroom:before {
    content: "\e911";
}
.icon-broker-access-light:before {
    content: "\e912";
}
.icon-broker-access-solid:before {
    content: "\e913";
}
.icon-broker-access:before {
    content: "\e914";
}
.icon-check-light:before {
    content: "\e915";
}
.icon-check-solid:before {
    content: "\e916";
}
.icon-check:before {
    content: "\e917";
}
.icon-close-light:before {
    content: "\e918";
}
.icon-close-solid:before {
    content: "\e919";
}
.icon-close:before {
    content: "\e91a";
}
.icon-darkmode-light:before {
    content: "\e91b";
}
.icon-darkmode-solid:before {
    content: "\e91c";
}
.icon-darkmode:before {
    content: "\e91d";
}
.icon-email-light:before {
    content: "\e91e";
}
.icon-email-solid:before {
    content: "\e91f";
}
.icon-email:before {
    content: "\e920";
}
.icon-expenses-light:before {
    content: "\e921";
}
.icon-expenses-solid:before {
    content: "\e922";
}
.icon-expenses:before {
    content: "\e923";
}
.icon-facebook-light:before {
    content: "\e924";
}
.icon-facebook-solid:before {
    content: "\e925";
}
.icon-facebook:before {
    content: "\e926";
}
.icon-faceing-light:before {
    content: "\e927";
}
.icon-faceing-solid:before {
    content: "\e928";
}
.icon-faceing:before {
    content: "\e929";
}
.icon-favoritos-heart-light:before {
    content: "\e92a";
}
.icon-favoritos-heart-solid:before {
    content: "\e92b";
}
.icon-favoritos-heart:before {
    content: "\e92c";
}
.icon-favoritos-star-light:before {
    content: "\e92d";
}
.icon-favoritos-star-solid:before {
    content: "\e92e";
}
.icon-favoritos-star:before {
    content: "\e92f";
}
.icon-filters-light:before {
    content: "\e930";
}
.icon-filters-solid:before {
    content: "\e931";
}
.icon-filters:before {
    content: "\e932";
}
.icon-floores-light:before {
    content: "\e933";
}
.icon-floors-solid:before {
    content: "\e934";
}
.icon-floors:before {
    content: "\e935";
}
.icon-g-logo-white:before {
    content: "\e936";
    color: #5a5b5f;
}
.icon-gallery-light:before {
    content: "\e937";
}
.icon-gallery-solid:before {
    content: "\e938";
}
.icon-gallery:before {
    content: "\e939";
}
.icon-garden-light:before {
    content: "\e93a";
}
.icon-garden-solid:before {
    content: "\e93b";
}
.icon-garden:before {
    content: "\e93c";
}
.icon-grid-view-light:before {
    content: "\e93d";
}
.icon-grid-view-solid:before {
    content: "\e93e";
}
.icon-grid-view:before {
    content: "\e93f";
}
.icon-instagram-light:before {
    content: "\e940";
}
.icon-instagram-solid:before {
    content: "\e941";
}
.icon-instagram:before {
    content: "\e942";
}
.icon-investment-light:before {
    content: "\e943";
}
.icon-investment-solid:before {
    content: "\e944";
}
.icon-investment:before {
    content: "\e945";
}
.icon-investor-access-light:before {
    content: "\e946";
}
.icon-investor-access-solid:before {
    content: "\e947";
}
.icon-investor-access:before {
    content: "\e948";
}
.icon-label-light:before {
    content: "\e949";
}
.icon-label-solid:before {
    content: "\e94a";
}
.icon-label:before {
    content: "\e94b";
}
.icon-lightmode-light:before {
    content: "\e94c";
}
.icon-lightmode-solid:before {
    content: "\e94d";
}
.icon-lightmode:before {
    content: "\e94e";
}
.icon-linkedin-light:before {
    content: "\e94f";
}
.icon-linkedin-solid:before {
    content: "\e950";
}
.icon-linkedin:before {
    content: "\e951";
}
.icon-location-light:before {
    content: "\e952";
}
.icon-location-solid:before {
    content: "\e953";
}
.icon-location:before {
    content: "\e954";
}
.icon-measure-light:before {
    content: "\e955";
}
.icon-measure-solid:before {
    content: "\e956";
}
.icon-measure:before {
    content: "\e957";
}
.icon-oper-type-light:before {
    content: "\e958";
}
.icon-oper-type-solid:before {
    content: "\e959";
}
.icon-oper-type:before {
    content: "\e95a";
}
.icon-orientation-light:before {
    content: "\e95b";
}
.icon-orientation-solid:before {
    content: "\e95c";
}
.icon-orientation:before {
    content: "\e95d";
}
.icon-parking-light:before {
    content: "\e95e";
}
.icon-parking-solid:before {
    content: "\e95f";
}
.icon-parking:before {
    content: "\e960";
}
.icon-pet-allow-light:before {
    content: "\e961";
}
.icon-pet-allow-solid:before {
    content: "\e962";
}
.icon-pet:before {
    content: "\e963";
}
.icon-phone-light:before {
    content: "\e964";
}
.icon-phone-solid:before {
    content: "\e965";
}
.icon-phone:before {
    content: "\e966";
}
.icon-plus-light:before {
    content: "\e967";
}
.icon-plus-solid:before {
    content: "\e968";
}
.icon-plus:before {
    content: "\e969";
}
.icon-pool-light:before {
    content: "\e96a";
}
.icon-pool-solid:before {
    content: "\e96b";
}
.icon-pool:before {
    content: "\e96c";
}
.icon-prop-type-light:before {
    content: "\e96d";
}
.icon-prop-type-solid:before {
    content: "\e96e";
}
.icon-prop-type:before {
    content: "\e96f";
}
.icon-quote-light:before {
    content: "\e970";
}
.icon-quote-solid:before {
    content: "\e971";
}
.icon-quote:before {
    content: "\e972";
}
.icon-rooms-light:before {
    content: "\e973";
}
.icon-rooms-solid:before {
    content: "\e974";
}
.icon-rooms:before {
    content: "\e975";
}
.icon-row-view-light:before {
    content: "\e976";
}
.icon-row-view-solid:before {
    content: "\e977";
}
.icon-row-view:before {
    content: "\e978";
}
.icon-search-light:before {
    content: "\e979";
}
.icon-search-solid:before {
    content: "\e97a";
}
.icon-search:before {
    content: "\e97b";
}
.icon-share-light:before {
    content: "\e97c";
}
.icon-share-solid:before {
    content: "\e97d";
}
.icon-share:before {
    content: "\e97e";
}
.icon-toilette-light:before {
    content: "\e97f";
}
.icon-toilette-solid:before {
    content: "\e980";
}
.icon-toilette:before {
    content: "\e981";
}
.icon-tour-light:before {
    content: "\e982";
}
.icon-tour-solid:before {
    content: "\e983";
}
.icon-tour:before {
    content: "\e984";
}
.icon-twitter-light:before {
    content: "\e985";
}
.icon-twitter-solid:before {
    content: "\e986";
}
.icon-twitter:before {
    content: "\e987";
}
.icon-whatsapp-light:before {
    content: "\e988";
}
.icon-whatsapp-solid:before {
    content: "\e989";
}
.icon-whatsapp:before {
    content: "\e98a";
}
.icon-youtube-light:before {
    content: "\e98b";
}
.icon-youtube-solid:before {
    content: "\e98c";
}
.icon-youtube:before {
    content: "\e98d";
}