.Emprendimiento {
    .content {
        .js-projects-gallery {
            .section {
                .row {
                    .first_col {
                        .project {
                            background: transparent !important;
                            a {
                                figure {
                                    .div_zoom {
                                        opacity: 0;
                                        position: absolute;
                                        border: 1px solid $bd-7;
                                        border-radius: 10px;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        transition: 1s all;
                                        i {
                                            color: $color-1;
                                            font-size: 4rem;
                                        }
                                    }
                                    img {}
                                    figcaption {
                                        .project-category {
                                            // left: 3%;
                                            bottom: 3% !important;
                                        }
                                    }
                                    &:hover {
                                        img {
                                            transform: none !important;
                                            filter: blur(9px);
                                        }
                                        .div_zoom {
                                            z-index: 2;
                                            opacity: 1;
                                            top: 35%;
                                            left: 35%;
                                            bottom: 35%;
                                            right: 35%;
                                            transition: 1s all;
                                            i {}
                                        }
                                    }
                                    &::before {
                                        content: "";
                                        object-fit: cover;
                                        position: absolute;
                                        left: 0;
                                        top: 0;
                                        bottom: 0;
                                        right: 0;
                                        width: 100%;
                                        // height: calc(100vh);
                                        height: 100%;
                                        opacity: 0.2;
                                        z-index: 1;
                                        background-color: black;
                                    }
                                }
                            }
                        }
                    }
                    .second_col {
                        .first_child_col {
                            .project {
                                background: transparent !important;
                                a {
                                    figure {
                                        .div_zoom {
                                            opacity: 0;
                                            position: absolute;
                                            border: 1px solid $bd-7;
                                            border-radius: 10px;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: 1s all;
                                            i {
                                                color: $color-1;
                                                font-size: 4rem;
                                            }
                                        }
                                        img {}
                                        figcaption {
                                            .project-category {
                                                // left: 3%;
                                                bottom: 3% !important;
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: none !important;
                                                filter: blur(9px);
                                            }
                                            .div_zoom {
                                                z-index: 2;
                                                opacity: 1;
                                                top: 35%;
                                                left: 35%;
                                                bottom: 35%;
                                                right: 35%;
                                                transition: 1s all;
                                                i {}
                                            }
                                        }
                                        &::before {
                                            content: "";
                                            object-fit: cover;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                            // height: calc(100vh);
                                            height: 100%;
                                            opacity: 0.2;
                                            z-index: 1;
                                            background-color: black;
                                        }
                                    }
                                }
                            }
                        }
                        .second_child_col {
                            .project {
                                a {
                                    figure {
                                        .div_zoom {
                                            opacity: 0;
                                            position: absolute;
                                            border: 1px solid $bd-7;
                                            border-radius: 10px;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: 1s all;
                                            i {
                                                color: $color-1;
                                                font-size: 4rem;
                                            }
                                        }
                                        img {}
                                        figcaption {
                                            .project-category {
                                                bottom: 3% !important;
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: none !important;
                                                filter: blur(9px);
                                            }
                                            .div_zoom {
                                                z-index: 2;
                                                opacity: 1;
                                                top: 35%;
                                                left: 35%;
                                                bottom: 35%;
                                                right: 35%;
                                                transition: 1s all;
                                                i {}
                                            }
                                        }
                                        &::before {
                                            content: "";
                                            object-fit: cover;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                            height: calc(100vh);
                                            opacity: 0.2;
                                            z-index: 1;
                                            background-color: black;
                                        }
                                    }
                                }
                            }
                        }
                        .third_child_col {
                            .project {
                                a {
                                    figure {
                                        .div_zoom {
                                            opacity: 0;
                                            position: absolute;
                                            border: 1px solid $bd-7;
                                            border-radius: 10px;
                                            top: 0;
                                            left: 0;
                                            bottom: 0;
                                            right: 0;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            transition: 1s all;
                                            i {
                                                color: $color-1;
                                                font-size: 4rem;
                                            }
                                        }
                                        img {}
                                        figcaption {
                                            .project-category {
                                                bottom: 3% !important;
                                            }
                                        }
                                        &:hover {
                                            img {
                                                transform: none !important;
                                                filter: blur(9px);
                                            }
                                            .div_zoom {
                                                z-index: 2;
                                                opacity: 1;
                                                top: 35%;
                                                left: 35%;
                                                bottom: 35%;
                                                right: 35%;
                                                transition: 1s all;
                                                i {}
                                            }
                                        }
                                        &::before {
                                            content: "";
                                            object-fit: cover;
                                            position: absolute;
                                            left: 0;
                                            top: 0;
                                            bottom: 0;
                                            right: 0;
                                            width: 100%;
                                            height: calc(100vh);
                                            opacity: 0.2;
                                            z-index: 1;
                                            background-color: black;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .div_bottom_arrow {
                    //   transform: rotate(90deg);
                    margin-top: 4rem;
                    z-index: 1;
                    position: relative;
                    min-height: 300px;
                    .logo-animation-container {
                        // right: 50%;
                        // transform: translate(-50%, -50%);
                        .circle_desarrollo {
                            position: absolute;
                            opacity: .4;
                            // bottom: -175px;
                            width: 20rem;
                        }
                        .logo-center-letter {
                            width: 10rem;
                            // bottom: -100px;
                        }
                        a {
                            border: 1px solid $bd-9;
                            border-radius: 49%;
                            padding: 2.6rem;
                            position: absolute;
                            transition: 1s all;
                            span {
                                position: absolute;
                                font-size: 2rem;
                                color: $color-13;
                                top: 20px!important;
                                left: 22px!important;
                                transform: rotate(90deg);
                                transition: 1s all;
                            }
                            &:hover {
                                border-color: $bd-10;
                                span {
                                    top: 35px!important;
                                    color: $color-12;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}