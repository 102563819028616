.loading-section {
    height: calc(100vh);
    margin-top: 6.5rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    vertical-align: middle;
    &.screen {
        height: unset;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    z-index: 9999;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: var(--primary-color) transparent var(--primary-color) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}