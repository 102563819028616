.main_contact {
  padding-top: 7rem;
  .container-fluid {
    .contact-container {
      h2 {
        color: $color-14;
      }
      h3 {
        color: $color-2;
      }
      &::before {
        top: 45%;
        left: 10%;
        z-index: 1;
        opacity: 0.05;
        color: $color-2;
        font-size: 16rem;
        position: absolute;
        white-space: nowrap;
        content: "Mapa";
        transform: translate(-50%, -50%);
    }
    }
  }
  .div_map_contact {
    height: 75vh;
  }
}

@media (max-width: 992px) {
  .main_contact {
    .div_map_contact {
      height: 60vh;
    }
  }
}
