.novedad {
    .layout {
        .main {
            iframe{
                width: 100%;
                height: 50vh;
            }
            padding-top: 7rem;
            padding-left: 0px;
            @media (min-width: 768px) and (max-width: 991px) { 
                padding-left: 140px;
            }
            .rdw-editor-toolbar {
                display: none !important;
            }
            .rdw-image-center {
                display: block;
            }
            .rdw-image-alignment-options-popup {
                display: none !important;
            }
            .rdw-editor-main {
                font-family: var(--first-font) !important;
                a span {
                    text-decoration: underline 1px solid $color-0 !important;
                    color: $color-0 !important;
                }
                span {
                    font-family: $font-1;
                    background-color: transparent !important;
                    font-size: 1.2rem;
                    color: $color-2 !important;
                }
            }
            .public-DraftStyleDefault-block {
                margin: .5rem 0 !important;
            }
            .container-fluid {
                .primer_bloque {
                    .div_novedad {
                        padding-left: 4rem;
                        padding-right: 5rem;
                        padding-bottom: 5rem;
                        .fecha {
                            color: $color-2;
                            opacity: .5;
                            font-family: $font-1;
                        }
                        .titulo {
                            color: $color-2;
                        }
                        .subtitulo {
                            color: $color-2;
                            opacity: .5;
                            font-family: $font-1;
                        }
                        .publicado_por {
                            color: $color-2;
                            opacity: .5;
                            font-family: $font-1;
                        }
                        .texto_novedad {
                            color: $color-7;
                            font-family: $font-1;
                        }
                        .div_video {
                            height: 393px;
                            width: 100%;
                            background-color: gray;
                        }
                        .texto_novedad_2 {
                            color: $color-7;
                            font-family: $font-1;
                        }
                        .hr_novedad {
                            height: 1px;
                            width: 100%;
                            color: $color-3;
                            opacity: 1;
                        }
                        .pills {
                            .pill {}
                        }
                    }
                }
                .segundo_bloque {
                    background-color: $bg-4;
                    padding-top: 2.5rem;
                    padding-bottom: 10rem;
                    @media (min-width: 992px) {
                        .novedades-wrap {
                            max-width: 100%!important;
                        }
                    }
                    .titulo {
                        color: $color-6;
                        font-size: 2rem;
                    }
                    .div_novedades_destacadas {}
                }
            }
        }
    }
}

@media (max-width: 1500px) {
    .novedad {
        .layout {
            .main {
                .container-fluid {
                    .primer_bloque {
                        .div_novedad {
                            .fecha {}
                            .titulo {}
                            .subtitulo {}
                            .publicado_por {}
                            .texto_novedad {}
                            .div_video {}
                            .texto_novedad_2 {}
                            .hr_novedad {}
                            .pills {
                                .pill {}
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .novedad {
        .layout {
            .main {
                .container-fluid {
                    .primer_bloque {
                        .div_novedad {
                            .fecha {}
                            .titulo {}
                            .subtitulo {}
                            .publicado_por {}
                            .texto_novedad {}
                            .div_video {}
                            .texto_novedad_2 {}
                            .hr_novedad {}
                            .pills {
                                .pill {}
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 770px) {
    .novedad {
        .layout {
            .main {
                .container-fluid {}
            }
        }
    }
}

@media (max-width: 992px) {
    .novedad {
        .layout {
            .main {
                .container-fluid {
                    .primer_bloque {
                        .div_novedad {
                            .fecha {}
                            .titulo {}
                            .subtitulo {}
                            .publicado_por {}
                            .texto_novedad {}
                            .div_video {
                                height: 190px;
                            }
                            .texto_novedad_2 {}
                            .hr_novedad {}
                            .pills {
                                .pill {}
                            }
                        }
                    }
                    .segundo_bloque {}
                }
            }
        }
    }
}