.emprendimientos {
  background-color: #fff;
  .content {
    .projects {
      .blog-tags {
        position: relative;
        .btn-filters-blog {
          .buttonAnimation {
            &:hover {
              transition: 0.5s all;
            }
          }
        }
        p {
          position: absolute;
          right: 11%;
          bottom: 20%;
          transform: rotate(90deg);
          color: $color-3;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .emprendimientos {
    .content {
      .projects {
        .blog-tags {
          a {
            display: block;
            button {
              width: 95%;
              margin: 0;
            }
          }
          p {
            display: none;
          }
        }
      }
    }
  }
}
