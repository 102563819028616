.div_unidades {
    background: $bg-7;
    position: relative;
    margin-top: 1rem;
    .collapsed .sub-title {
        opacity: 0;
    }
    .fluid-1 {
        max-width: 90%;
        padding: 10rem 0rem 3rem 0rem;
        // margin-right: inherit;
        position: relative;
        p {
            bottom: 5rem;
            color: $color-5;
            left: -42px;
            transform: rotate(270deg);
        }
        h2 {
            color: $color-2;
            &.active {
                background: $bg-9 !important;
                border: 1px solid;
                color: $color-1;
            }
        }
    }
}

.fluid-2 {
    // margin-right: inherit;
    max-width: 90%;
    // border-left: 2px solid $bd-2;
    padding: 0rem 10rem 8rem 10rem;
    h3 {
        color: $color-8;
    }
    .unities {
        .div_ambientes {
            .div_tags {
                .col-lg-2 {
                    @media (max-width: 576px) {
                        width: 100%!important;
                    }
                    @media (min-width: 577px) and (max-width: 992px) {
                        width: 33.33333333%!important;
                    }
                }
                // justify-content: space-evenly;
                .tag-filter {
                    // padding: 1rem 2rem;
                    // border-radius: 5px;
                    cursor: pointer;
                    border: 1px solid $bd-11;
                    background: transparent !important;
                    transition: 0.4s ease all;
                    color: $color-8;
                    &.active, &:hover {
                        background: $bg-3!important;
                        border: 1px solid $bd-11;
                        color: $color-5;
                    }
                }
            }
        }
        .div_pisos {
            .unity {
                border-bottom: 1px solid $bd-11;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
                &.collapsed {
                    .col_arrow {
                        i {
                            transform: rotate(90deg) !important;
                        }
                    }
                }
                .head-row {
                    height: 55px;
                    display: flex;
                    align-items: baseline;
                    .unity-font {
                        color: $color-2;
                    }
                    .col_arrow {
                        i {
                            color: $color-2;
                            transform: rotate(270deg);
                        }
                    }
                }
                .close-deployable {
                    .unity-row {
                        .unity-font {
                            color: $color-13;
                        }
                    }
                }
            }
        }
        .div_unidad_inactiva {
            p {
                color: $color-13;
                // margin-top: 7rem;
            }
        }
    }
}

@media (max-width: 1600px) {
    .div_unidades {
        .fluid-1 {
            p {
                left: -37px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .div_unidades {
        .fluid-1 {
            p {}
            h2 {}
        }
        .fluid-2 {
            h3 {}
            .unities {
                .div_ambientes {
                    .div_tags {
                        .tag-filter {
                            &.active {}
                        }
                    }
                }
                .div_pisos {
                    .unity {
                        &.collapsed {
                            .col_arrow {
                                i {}
                            }
                        }
                        .head-row {
                            .unity-font {
                                font-size: 1.5rem !important;
                            }
                            .col_arrow {
                                i {}
                            }
                        }
                        .close-deployable {
                            .unity-row {
                                .unity-font {
                                    font-size: 1.3rem !important;
                                }
                            }
                        }
                    }
                }
                .div_unidad_inactiva {
                    p {}
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .div_unidades {
        .fluid-1 {
            p {
                left: -28px;
            }
        }
        .fluid-2 {
            padding: 0rem 1rem 0rem 1rem;
            .unities {
                .div_ambientes {
                    .div_tags {
                        justify-content: center;
                        .tag-filter {
                            &.active {}
                        }
                    }
                }
                .fluid-2 {
                    padding: 0rem 1rem 8rem 1rem;
                    h3 {}
                    .unities {
                        .div_ambientes {
                            .div_tags {
                                justify-content: center;
                            }
                        }
                        .div_pisos {
                            .unity {
                                &.collapsed {
                                    .col_arrow {
                                        i {}
                                    }
                                }
                                .head-row {
                                    .unity-font {}
                                    .col_arrow {
                                        i {}
                                    }
                                }
                                .close-deployable {
                                    .unity-row {
                                        .unity-font {
                                            font-size: 1rem !important;
                                        }
                                    }
                                }
                            }
                        }
                        .div_unidad_inactiva {
                            p {}
                        }
                    }
                }
            }
        }
    }
}