.Resultados {
    .filter {
        padding: 6rem 6rem;
        padding-top: 15rem;
        // padding-top: 25rem;
        // border-radius: 10px;
        max-width: 100%;
        background-color: #797979;
        border: 1px solid #707070;
        position: fixed;
        overflow-y: scroll;
        z-index: 99;
        max-height: 100vh;
        min-height: 100vh;
        width: 36.5vw;
        top: 0;
        right: -100%;
        transition: .4s ease all;
        &.open {
            right: 0;
        }
        &::-webkit-scrollbar {
            width: 6px;
        }
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }
        &::-webkit-scrollbar-thumb {
            background: rgba(var(--secondary-color), .7);
        }
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(var(--secondary-color), .7);
        }
        &_header {
            h3 {
                font-size: 2.25rem;
                color: #fff;
                font-weight: 400;
                margin-bottom: 2rem;
                letter-spacing: -1%;
                background-color: transparent;
            }
            button {
                border: none;
                font-size: .75rem;
                background-color: transparent;
                color: var(--primary-color);
            }
            .current-tags {
                margin-top: 2rem;
                .tag {
                    background-color: #0F0F0F;
                    padding: .5rem 1rem;
                    text-transform: uppercase;
                    margin-right: .5rem;
                    margin-bottom: .5rem;
                    font-size: .8rem;
                    font-weight: 400;
                    color: #fff;
                    border: none;
                    .btn-delete-tag {
                        margin-left: 1rem;
                        height: 1px;
                        width: 14px;
                        position: relative;
                        cursor: pointer;
                        display: block;
                        background-color: #fff;
                        transform: rotate(-45deg);
                        &::before {
                            content: '';
                            height: 1px;
                            width: 14px;
                            position: absolute;
                            display: block;
                            background-color: #fff;
                            transform: rotate(90deg);
                        }
                    }
                }
            }
            .close-filter {
                border: 2px solid rgba(var(--secondary-color), 1);
                width: 3.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                vertical-align: middle;
                height: 3.5rem;
                transition: .4s ease all;
                padding: 2rem;
                span {
                    width: 1.5rem;
                    height: 2px;
                    background-color: #000000;
                    display: block;
                    &:nth-child(1) {
                        transform: rotate(45deg);
                    }
                    &:nth-child(2) {
                        transform: rotate(-45deg) translate(1px, -1px);
                    }
                }
                &:hover {
                    background: var(--primary-color) !important;
                    border: 2px solid var(--primary-color);
                    span {
                        color: #fff;
                    }
                }
            }
        }
        &_body {
            .filters-group {
                border-top: 1px solid #fff;
                h3 {
                    font-size: 1.5rem;
                    color: #fff;
                    // font-family: 'LEMON MILK';
                    font-weight: 400;
                    margin-bottom: 0;
                }
                .collapse-btn {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    span {
                        padding: 7px;
                        width: 26px;
                        height: 26px;
                        display: inline-block;
                        position: relative;
                        font-weight: 500;
                        font-size: .8rem;
                        line-height: 1;
                        border-radius: 50%;
                        color: var(--secondary-color);
                        border: 2px solid var(--secondary-color);
                        &:before,
                        &:after {
                            content: '';
                            width: 10px;
                            height: 2px;
                            background: var(--secondary-color);
                            position: absolute;
                            top: 10px;
                            left: 6.5px;
                            transition: 0.3s;
                        }
                    }
                    &.collapsed {
                        span {
                            &:after {
                                transform: rotate(90deg);
                                transform-origin: center;
                            }
                        }
                    }
                }
                input[type=text] {
                    border: 1px solid #fff;
                    border-radius: 10px;
                    padding: .8rem 1.7rem;
                    background-color: transparent;
                    line-height: 1;
                    font-size: 1rem;
                    width: 80%;
                    &::placeholder {
                        color: #fff;
                    }
                }
                .options {
                    .content-collapse {
                        padding: 1.3rem 0 .8rem 0;
                        .text-danger {
                            font-weight: 400;
                        }
                        &.non-slider {
                            .noUi-target {
                                opacity: .8;
                                // // cursor: not-allowed
                            }
                        }
                        .noUi-target {
                            background: #707070;
                            // margin: 2rem 0;
                            border-color: #707070;
                        }
                        .noUi-horizontal {
                            height: 4px;
                        }
                        .noUi-connect {
                            background: var(--secondary-color);
                            height: 4px;
                            border-color: #1f1f1f;
                        }
                        .noUi-connects {
                            background-color: #707070;
                        }
                        .noUi-horizontal {
                            .noUi-handle {
                                width: 19px;
                                border-radius: 50%;
                                height: 19px;
                                top: -9px;
                                background: #646464;
                                border: none;
                                outline: none !important;
                                box-shadow: none;
                                &:after {
                                    display: none;
                                }
                                &:before {
                                    height: 9px;
                                    width: 9px;
                                    background: var(--secondary-color);
                                    left: 5px;
                                    top: 5px;
                                    border-radius: 50%;
                                }
                                &.noUi-handle-upper {
                                    right: -3px;
                                }
                                &.noUi-handle-lower {
                                    right: -17px;
                                }
                            }
                            .noUi-tooltip {
                                background: transparent;
                                border: none;
                                top: 120%;
                                font-size: .8rem;
                            }
                        }
                        .option {
                            display: flex;
                            margin-bottom: .67rem;
                            color: #fff;
                            cursor: pointer;
                            input {
                                display: none;
                                &:checked+span {
                                    background: var(--secondary-color);
                                    border: 0;
                                    &:before {
                                        background: var(--secondary-color);
                                        transform: translate(-50%, -50%) scale(1);
                                    }
                                }
                            }
                            span {
                                display: inline-block;
                                vertical-align: middle;
                                position: relative;
                                margin-right: .9rem;
                                width: 22px;
                                height: 22px;
                                border: 1px solid #fff;
                                background: transparent;
                                &:before {
                                    content: '';
                                    display: inline-block;
                                    width: 10px;
                                    height: 10px;
                                    transition: 0.3s;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%) scale(0);
                                }
                            }
                            .circle {
                                border-radius: 50%;
                                &::before {
                                    border-radius: 50%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:992px) {
    .Resultados {
        .close-filter {
            span {
                width: 1.5rem;
                height: 2px;
                background-color: #000000;
                // margin-bottom: 5px;
                display: block;
                &:nth-child(1) {
                    transform: rotate(45deg);
                }
                &:nth-child(2) {
                    transform: rotate(-45deg) translate(1px, -1px);
                }
            }
        }
        .filter {
            position: fixed;
            top: 0%;
            bottom: 0;
            left: 200%;
            right: 200%;
            z-index: 9999999;
            max-width: 100%;
            border: none;
            border-radius: 0;
            overflow: scroll;
            padding: 5rem 1rem;
            width: 100%;
            height: 100vh;
            transition: .3s ease all;
            &.open {
                right: 0;
                left: 0;
            }
            .filters-group {
                h3 {
                    font-size: 1.2rem;
                }
                .options .content-collapse .option {
                    font-size: .85rem;
                    letter-spacing: .7px;
                }
            }
        }
    }
}