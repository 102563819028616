.div_about {
  position: relative;
  overflow: hidden;
  // padding: 7rem 0rem 0rem 0rem;
  padding: 20vh 0 35vh 0;
  background-color: $bg-4;
  display: flex;
  align-items: center;
  .position-absolute.top-0.end-0 {
    margin-top: 10vh;
    margin-right: 5vw;
    .img-fluid {
      // width: 85%;
      z-index: 3;
      box-shadow: 0px 0px 44px 8px $bg-6;
    }
    &::before {
      content:'Natural';
      font-family: $font-0;
      color: $color-2;
      position: absolute;
      top: -210px;
      right: -10px;
      font-size: 20rem;
      opacity: 0.1;
      z-index: 1;
      background: -webkit-linear-gradient($color-2, $color-5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .text-container {
    z-index: 2;
    .position-absolute {
      left: -10vw;
      bottom: 13vh;
      z-index: 4;
      .img-fluid {
        // width: 60%;
        opacity: 0.2;
      }
    }
    .text-title {
      font-size: 10vw;
      color: $color-2;
      z-index: 5;
      text-shadow:0px 0px 40px var(--white);
      span{
        // box-shadow: -94px -8px 64px 15px var(--white);
        // background-color: $bg-7;
      }
    }
    .text-content {
      // max-width: 310px;
      font-size: 1.7rem;
      max-width: 30vw;
      color: $color-0;
      align-self: flex-end;
      margin-right: 0vw;
      z-index: 2;
    }
    .icon-arrow-stick-light {
      color: $color-0;
      z-index: 2;
      // font-size: 1.5rem;
    }
  }
  .position-absolute.bottom-0.start-0 {
    bottom: 15vh!important;
    margin-bottom: 3rem;
    margin-left: 15vw;
    z-index: 99;
    .img-fluid {
      // width: 85%;
      z-index: 3;
      box-shadow: 0px 0px 44px 8px $bg-6;
    }
    &::after {
      content:'Excelencia';
      font-family: $font-0;
      color: $color-2;
      position: absolute;
      top: 170px;
      left: -50px;
      font-size: 20rem;
      opacity: 0.1;
      z-index: 1;
      background: -webkit-linear-gradient($color-2, $color-5);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

@media (max-width: 992px) {
  .div_about {
    padding: 40rem 0rem;
    .text-container {
      .position-absolute {
        .img-fluid {
          opacity: 0;
        }
      }
      .text-title {
        font-size: 8rem;
      }
      .text-content {
        align-self: center;
        margin-right: 0;
        max-width: 70vw;
      }
    }
  }
}

@media (max-width: 768px) {
  .div_about {
    padding: 45rem 0rem 42rem 0;
  }
}
