.video {
    background: #ddddde50;
    position: relative;
    .video-title-container {
        color: $color-13;
        &::before {
            top: 10%;
            left: 50%;
            z-index: 1;
            opacity: 0.05;
            color: $color-2;
            font-size: 16rem;
            position: absolute;
            white-space: nowrap;
            content: "Video";
            transform: translate(60%, -10%);
        }
    }
    .fluid-1 {
        max-width: 90%;
        padding: 15rem 0rem 3rem 10rem;
        margin-right: inherit;
        position: relative;
        p {
            bottom: 5rem;
            color: $color-8;
            left: -25px;
            transform: rotate(270deg);
        }
    }
    .fluid-2 {
        // margin-right: inherit;
        max-width: 90%;
        // border-left: 2px solid $bd-5;
        padding: 0rem 10rem 8rem 10rem;
        .div_heard_video {
            h2 {
                color: $color-13;
            }
            margin-bottom: 5rem;
        }
        .div_video {
            max-width: 1200px;
            margin: auto;
            margin-bottom: 5rem;
            iframe {
                border: 0 !important;
            }
        }
    }
    .border-bot {
        border-bottom: 1px solid $bd-11;
    }
}

@media (max-width: 1600px) {
    .video {
        .fluid-1 {
            p {
                left: -22px;
            }
        }
        .fluid-2 {
            .div_video {
                // width: 900px;
                // height: 500px;
            }
        }
    }
}

@media (max-width: 1300px) {
    .video {
        .fluid-2 {
            padding: 0rem 2rem 8rem 2rem;
            .div_video {
                // width: 600px;
                // height: 260px;
            }
        }
    }
}

@media (max-width: 992px) {
    .video {
        .fluid-1 {}
        .fluid-2 {
            padding: 0rem 2rem 8rem 2rem;
            .div_video {
                // width: 400px;
                // height: 200px;
            }
        }
    }
}

@media (max-width: 500px) {
    .video {
        .fluid-1 {}
        .fluid-2 {
            padding: 0rem 2rem 8rem 2rem;
            .div_video {
                // width: 270px;
                // height: 150px;
            }
        }
    }
}