.chart-container {
    color: $color-2;
    padding: 10rem 0rem 9rem 0rem;
    h2.text-center {
        z-index: 2;
        font-size: 6rem;
        margin: 2rem 0 6rem 0;
    }
    &::before {
        top: 13%;
        @media (max-width: 991px) {
            top: 15%;
        }
        left: 22%;
        z-index: 1;
        opacity: 0.05;
        color: $color-2;
        font-size: 16rem;
        position: absolute;
        white-space: nowrap;
        content: "Greenville";
        transform: translate(-50%, -50%);
    }
}
@media (max-width: 992px) {
    .chart-container {
        .img-container {
            .img-fluid {
                max-width: 80%;
                margin: 5rem 0;
            }
        }
    }
}