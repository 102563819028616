.property-card {
  position: relative;
  // box-shadow: 10px 10px 30px #00000020;
  &:hover {
    .content-header {
      img {
        transform: scale(1.2);
      }
    }
  }
  .content-header {
    position: relative;
    overflow: hidden;
    img {
      width: 100%;
      transition: 0.5s ease all;
      min-height: 30vh;
      max-height: 30vh;
      object-fit: cover;
      object-position: center;
    }
    .type-property {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      padding: 0.5rem;
      font-weight: 300;
      line-height: 1;
      padding-left: 1rem;
      color: #fff;
    }
    &::before {
      position: absolute;
      content: "";
      top: -1px;
      bottom: -1px;
      z-index: 1;
      left: -1px;
      right: -1px;
      background: linear-gradient(320deg, transparent 90%, #ffffff 7%);
      transform: rotate(180deg);
    }
  }
  .top-card {
    position: absolute;
    display: flex;
    z-index: 4;
    top: 1rem;
    right: 1rem;
    font-size: 0.8rem;
    padding-right: 1.5rem;
    background-color: #000000;
    .operation-property {
      color: #fff;
      text-transform: uppercase;
      font-weight: 300;
      padding: 0.5rem 0rem 0.5rem 0rem;
    }
    .btn-like {
      display: flex;
      padding: 0 1rem 0;
      justify-content: center;
      border: none;
      align-items: center;
      vertical-align: middle;
      background-color: transparent;
      transition: 0.5s ease all;
      i {
        transition: 0.5s ease all;
        // color: $primary-color;
        color: transparent;
        -webkit-text-stroke: 2px var(--primary-color);
        font-size: 1.2rem;
      }
      &:hover {
        // background: $tertiary-color;
        i {
          color: var(--primary-color);
          // -webkit-text-stroke: 2px transparent;
        }
      }
      &.active {
        i {
          color: var(--primary-color);
        }
      }
    }
  }
  .content-body {
    padding: 1.8rem 0rem 0 0rem;
    a {
      text-decoration: none;
    }
    .title {
      font-size: 1.7rem;
      font-weight: 300;
      // font-family: 'LEMON MILK';
      color: var(--secondary-color);
      text-decoration: none;
      margin-bottom: 1rem;
    }
    .location {
      font-weight: 200;
      i {
        font-size: 1rem;
        color: var(--primary-color);
      }
      margin-bottom: 1.2rem;
    }
    .details {
      &_item {
        font-size: 1rem;
        color: #797979;
        font-weight: 400;
        line-height: 1rem;
        i {
          font-size: 1rem;
          color: #000;
          &.icon-surface-terreno {
            font-size: 1.2rem;
            // margin-bottom: -10px;
            // display: inline-flex;
            // &::before {
            //     line-height: 1;
            // }
            // line-height: 1rem;
            // margin-top: 5px;
          }
        }
      }
    }
    &_bottom {
      margin-top: 3rem;
      padding-bottom: 1.75rem;
      .price {
        font-size: 2.25rem;
        // font-family: 'LEMON MILK';
        font-weight: 400;
        &.min-size {
          font-size: 2rem;
        }
      }
      .btn-more {
        width: 2.25rem;
        padding: 0;
        height: 2.25rem;
        background: transparent;
        color: var(--primary-color);
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        vertical-align: middle;
        border-radius: 5px;
        border: 2px solid var(--primary-color);
        line-height: 1;
        text-decoration: none;
        span {
          position: absolute;
          top: 50%;
          height: 2.25rem;
          font-weight: 400;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 2rem;
          line-height: initial;
          display: block;
        }
        &:hover {
          border: 2px solid var(--primary-color);
          color: #fff;
        }
      }
    }
  }
  &.list {
    height: 100%;
    .content-header {
      width: 40%;
      overflow: hidden;
      max-height: 100%;
      height: 100%;
      img {
        max-height: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 0px 0px 20px 0px;
      }
    }
    .content-body {
      width: 65%;
      padding-left: 3rem;
      .list-items {
        .type-property {
          color: #5b5b5b;
          background-color: transparent;
          border: 2px solid #5b5b5b;
          border-radius: 5px;
          line-height: unset;
          font-size: 0.8rem;
          letter-spacing: -0.2px;
          padding: 0.1rem 1.2rem;
          font-weight: 400;
          position: relative;
        }
      }
      .details_item,
      .details i,
      .location {
        color: #a7a7a7;
      }
      .location {
        font-weight: 300;
      }
      &_bottom {
        margin-top: 1rem;
        padding-top: 1.3rem;
        padding-bottom: 1.3rem;
        border-top: 1px solid #c9c9c9;
        .btn-more {
          span {
            height: 2.2rem;
          }
        }
      }
    }
  }
  &.dark {
    .content-header {
      &::before {
        background: linear-gradient(
          320deg,
          transparent 90%,
          var(--secondary-color) 7%
        );
      }
    }
    .content-body {
      .title,
      .location {
        color: #fff;
      }
      .details {
        &_item {
          color: #797979;
          i {
            color: #797979;
          }
        }
      }
      &_bottom {
        .price {
          color: #fff;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .property-card {
    height: 100%;
    .top-card {
      padding-right: 1.2rem;
      .operation-property {
        font-size: 0.7rem;
        padding: 0.4rem 0rem 0.4rem 1rem;
      }
      .btn-like {
        i {
          font-size: 1rem;
        }
      }
    }
    .content-header {
      .type-property {
        font-size: 0.7rem;
      }
    }
    .content-body {
      padding: 0.8rem 0.8rem 0 0.8rem;
      .title {
        font-size: 1.3rem;
        margin-bottom: 0.8rem;
      }
      .location {
        font-size: 0.9rem;
        margin-bottom: 0.8rem;
        i {
          font-size: 0.8rem;
        }
      }
      .details {
        &_item {
          font-size: 0.9rem;
        }
        i {
          font-size: 0.7rem;
          &.icon-surface-terreno {
            font-size: 1rem;
          }
        }
      }
      &_bottom {
        margin-top: 1.3rem;
        padding-bottom: 0.8rem;
        .price {
          font-size: 1.7rem;
        }
      }
    }
  }
}
