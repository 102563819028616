.div_section_form {
  position: relative;
  padding: 16rem 0rem 6rem 0rem;
  background-color: $bg-0;
  color: $color-5;
  display: flex;
  align-items: center;
  a:hover{
    color:#fff !important;
  }
  .position-absolute {
    top: 0;
    right: 0;
    z-index: 1;
    transform: translate(20%, -10%);
    .img-fluid {
      width: 70%;
      opacity: 0.1;
    }
  }
  .container {
    z-index: 2;
    .div_news_container {
      z-index: 2;
      .div_title {
        padding-bottom: 4rem;
        margin-bottom: 4rem;
        h2 {
          color: $color-5;
          font-size: 6rem;
          background-color: $bg-0;
          box-shadow: -4px 0px 64px 28px $bg-0;
        }
      }
      .div_first {
        .contact-data {
          margin-top: 2.5rem;
          color: $color-1;
          p {
            margin: 1.2rem;
            font-size: 1.2rem;
            i {
              font-size: 2rem;
              margin-right: 0.5em;
            }
            a {
              color: $color-1;
              text-decoration: none;
            }
          }
        }
      }
      .div_second {
        z-index: 2;
        form {
          z-index: 2;
          p {
            color: $color-1;
            font-size: 1.1rem;
          }
          input {
            z-index: 2;
          }
          .captcha-submit {
            @media (min-width: 992px) {
              margin-top: -37px;
            }
          }
        }
      }
      .div_third {
        display: none;
        .contact-data {
          .text-center {
            font-size: 1.2rem;
            i {
              font-size: 2rem;
            }
            a {
              color: $color-5;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
@media screen and( min-width: 728px) {
}

@media (max-width: 991px) {
  .div_section_form {
      .div_news_container {
        padding: 1rem;
        .div_vertical {
          p {
          }
        }
        .div_title {
          h2 {
          }
        }
        .div_first {
          display: none;
        }
        .div_second {
        }
        .div_third {
          display: block!important;
        }
      }
  }
}
