.details {
  background: white;
  position: relative;
  padding-top: 5rem;
  // .fluid-1 {
  //   max-width: 90%;
  //   padding: 15rem 0rem 3rem 10rem;
  //   margin-right: inherit;
  //   position: relative;
  //   .div_news__title {
  //     position: absolute;

  //     h2 {
  //       color: $color-5;
  //       opacity: 0.14;
  //       margin-left: 23rem;
  //     }
  //   }
  //   .div_heard_project {
  //     h2 {
  //       color: $color-2;
  //       span {
  //         color: $color-0;
  //       }
  //     }
  //     .div_arrows {
  //     }
  //   }
  //   p {
  //     bottom: 5rem;
  //     color: $color-2;
  //     left: -67px;
  //     transform: rotate(270deg);
  //   }
  // }
  .fluid-2 {
    // margin-right: inherit;
    max-width: 90%;
    position: relative;
    // border-left: 2px solid $color-2;
    padding: 0rem 10rem 8rem 10rem;
    .section-caracteristica {
      .div_marco_fondo {
        width: 60%;
        left: 20%;
        top: -9rem;
        height: 100%;
        position: absolute;
        // border: 1px solid $color-2;
      }

      .div_caracteristicas {
        .first_col {
          z-index: 1;
          .img1 {
            width: 644px;
            // height: 647px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
            width: -webkit-fill-available;
            box-shadow: 0px 30px 60px #00000029;
            border-radius: 2px;
          }
          .div_text_1 {
            background: $bg-18;
            color: $color-1;
            padding: 15rem 8rem;
            // width: 644px;
            // height: 647px;
            display: flex;
            align-items: center;
            margin-top: 20rem;
            box-shadow: 0px 30px 60px #00000029;
            position: relative;
            @media (min-width: 992px) {
              margin-right: -8%;
            }
            &::before {
              top: -20%;
              right: 10%;
              z-index: -1;
              content: "";
              width: 100%;
              height: 100%;
              opacity: 0.2;
              position: absolute;
              border: .5px solid $bd-2;
            }
          }
          .img3 {
            width: 644px;
            margin-top: 15rem;
            height: 647px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
            width: -webkit-fill-available;
            margin-top: 15rem;
            box-shadow: 0px 30px 60px #00000029;
            border-radius: 2px;
          }
          .div_news__title {
            position: absolute;
            bottom: 0;
            h2 {
              color: $color-5;
              opacity: 0.14;
              margin-left: -58rem;
            }
          }
        }
        .second_col {
          z-index: 1;
          .div_text_1 {
            background: $bg-18;
            color: $color-1;
            padding: 15rem 8rem;
            // width: 644px;
            // height: 647px;
            display: flex;
            align-items: center;
            margin-top: 5rem;
            box-shadow: 0px 30px 60px #00000029;
            position: relative;
            @media (min-width: 992px) {
              margin-left: -10%;
            }
            &::before {
              top: -20%;
              z-index: -1;
              content: "";
              width: 100%;
              height: 100%;
              opacity: 0.2;
              position: absolute;
              border: .5px solid $bd-2;
            }
          }
          .img2 {
            width: 644px;
            margin-top: 20rem;
            // height: 647px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
            width: -webkit-fill-available;
            box-shadow: 0px 30px 60px #00000029;
            border-radius: 2px;
          }
          .div_text_2 {
            background: $bg-18;
            color: $color-1;
            padding: 15rem 8rem;
            // width: 644px;
            // height: 647px;
            display: flex;
            align-items: center;
            margin-top: 20rem;
            box-shadow: 0px 30px 60px #00000029;
            position: relative;
            @media (min-width: 992px) {
              margin-left: -10%;
            }
            &::before {
              top: -20%;
              z-index: -1;
              content: "";
              width: 100%;
              height: 100%;
              opacity: 0.2;
              position: absolute;
              border: .5px solid $bd-2;
            }
          }
        }
      }
    }
    &::before {
      top: 26%;
      @media (max-width: 992px) {
        top: 30%;
      }
      left: 0%;
      z-index: 1;
      opacity: 0.05;
      color: $color-2;
      font-size: 15rem;
      position: absolute;
      white-space: nowrap;
      content: "Características";
      transform: translate(-10%, -50%);
    }
  }
}
@media (max-width: 1500px) {
  .details {
    // .fluid-1 {
    //   .div_heard_project {
    //     h2 {
    //       span {
    //       }
    //     }
    //     .div_arrows {
    //     }
    //   }
    //   p {
    //     left: -50px;
    //   }
    // }
    .fluid-2 {
      .section-caracteristica {
        .div_marco_fondo {
        }

        .div_caracteristicas {
          .first_col {
            .img1 {
            }
            .div_text_1 {
              padding: 8rem 8rem;
            }
            .img3 {
              height: 440px;
            }
            .div_news__title {
              h2 {
              }
            }
          }
          .second_col {
            .div_text_1 {
              padding: 8rem 8rem;
            }
            .img2 {
            }
            .div_text_2 {
              padding: 8rem 8rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .details {
    // .fluid-1 {
    //   .div_heard_project {
    //     h2 {
    //       span {
    //       }
    //     }
    //     .div_arrows {
    //     }
    //   }
    //   p {
    //     left: -50px;
    //   }
    // }
    .fluid-2 {
      .section-caracteristica {
        .div_marco_fondo {
        }

        .div_caracteristicas {
          .first_col {
            .img1 {
            }
            .div_text_1 {
              padding: 6rem 3rem;
            }
            .img3 {
              height: 355px;
            }
            .div_news__title {
              h2 {
              }
            }
          }
          .second_col {
            .div_text_1 {
              padding: 6rem 3rem;
            }
            .img2 {
              // margin-top: 12rem;
            }
            .div_text_2 {
              padding: 6rem 3rem;
              // margin-top: 13rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .details {
    // .fluid-1 {
    //   .div_heard_project {
    //     h2 {
    //       span {
    //       }
    //     }
    //     .div_arrows {
    //     }
    //   }
    //   p {
    //   }
    // }
    .fluid-2 {
      padding: 0rem 4rem 8rem 4rem;
      .section-caracteristica {
        .div_marco_fondo {
        }

        .div_caracteristicas {
          .first_col {
            .img1 {
            }
            .div_text_1 {
              padding: 2rem 2rem;
              margin-top: 10rem;
              font-size: 1.3rem !important;
            }
            .img3 {
              height: 355px;
              margin-top: 10rem;
            }
            .div_news__title {
              h2 {
              }
            }
          }
          .second_col {
            .div_text_1 {
              padding: 2rem 2rem;
              margin-top: 10rem;
              font-size: 1.3rem !important;
            }
            .img2 {
              margin-top: 10rem;
            }
            .div_text_2 {
              padding: 2rem 2rem;
              margin-top: 10rem;
              font-size: 1.3rem !important;
            }
          }
        }
      }
    }
  }
}

// .section__details__emprendimiento {
//   padding-bottom: 8rem;
//   background-color: var(--secondary-color);
//   padding-top: 8rem;
//   .section__details__container {
//     // height: 100vh;
//     // padding: 2rem 0;
//     .section__image__container > img {
//       width: 48rem;
//       object-fit: cover;
//       object-position: center;
//     }
//     .section__1 {
//       margin-left: 0 !important;
//     }
//   }
// }

// .section__2 {
//   margin-right: 0 !important;
//   .section__image__container2 {
//     margin-left: 20vw;
//   }
// }

// .left-image-float {
//   left: 0;
//   right: unset;
// }

// .section__3 {
//   margin-left: 0 !important;
// }

// .section__details__text {
//   position: absolute;
//   top: 50%;
//   bottom: 50%;
//   transform: translate(-50%, -50%);
//   left: -10%;
//   padding: 4rem;
//   background-color: #8398b9;
//   width: 629px;
//   height: 457px;
//   opacity: 0.75;
//   border-bottom-right-radius: 6rem;
//   z-index: 2;
// }

// .section__details__text::before {
//   content: "";
//   position: absolute;
//   top: -5%;
//   right: -5%;
//   width: 629px;
//   height: 457px;
//   border: 2px solid var(--white);
//   border-bottom-right-radius: 6rem;
//   z-index: 1;
// }

// .image__details__circles {
//   display: none;
//   width: 50vw;
//   top: 50%;
//   bottom: 50%;
//   transform: translate(-50%, -50%);
//   right: -50%;
//   z-index: 99;
// }

// .section__details__text2 {
//   position: absolute;
//   top: 10%;
//   right: 0%;
//   padding: 4rem;
//   background-color: #8e8e8e;
//   width: 629px;
//   height: 457px;
//   opacity: 0.75;
//   border-bottom-left-radius: 6rem;
//   z-index: 2;
// }

// .section__details__text2::before {
//   content: "";
//   position: absolute;
//   top: -5%;
//   left: -5%;
//   width: 629px;
//   height: 457px;
//   border: 2px solid var(--white);
//   border-bottom-left-radius: 6rem;
//   z-index: 1;
// }

// .image__details__bg {
//   display: none;
//   width: 40vw;
//   top: 80%;
//   transform: translate(-50%, -50%);
//   right: -35%;
//   z-index: 99;
// }

// .section__details__text3 {
//   position: absolute;
//   top: 50%;
//   bottom: 50%;
//   transform: translate(-50%, -50%);
//   left: 5%;
//   padding: 4rem;
//   background-color: #8398b9;
//   width: 629px;
//   height: 457px;
//   opacity: 0.75;
//   border-bottom-right-radius: 6rem;
//   z-index: 2;
// }

// .section__image__3 {
//   width: 803px !important;
//   height: 804px !important;
// }

// @media (max-width: 1600px) {
//   .section__details__text3 {
//     left: 30%;
//   }
// }

// @media (max-width: 1400px) {
//   .section__details__text {
//     left: 10%;
//   }
// }

// @media (max-width: 2400px) {
//   .section__details__text {
//     left: 15%;
//   }
//   .section__details__text3 {
//     left: 40%;
//   }
// }

// @media (max-width: 992px) {
//   .section__image__container > img {
//     width: 90% !important;
//   }
//   .section__image__container {
//     .section__image__3 {
//       height: 100vw !important;
//       width: 100vw !important;
//     }
//   }
//   .section__details__emprendimiento {
//     padding-bottom: 0;
//     img {
//       height: 50vh !important;
//       width: 100% !important;
//       object-fit: cover;
//       object-position: center;
//     }
//   }
//   .section__details__text2,
//   .section__details__text,
//   .section__details__text3 {
//     right: -0%;
//     width: unset;
//     height: unset;
//     padding: 3rem 1.5rem;
//     font-size: 0.9rem;
//     p {
//       font-size: 0.9rem;
//     }
//   }
//   .section__details__text2 {
//     height: 35vh;
//     &::before {
//       height: 35vh;
//     }
//   }
//   .section__details__text3 {
//     left: 50% !important;
//   }
//   .section__details__text,
//   .section__details__text3 {
//     left: 30%;
//     width: 80vw;
//     object-position: center;
//     object-fit: cover;
//     height: 30vh;
//     &::before {
//       width: 80vw;
//       height: 30vh;
//     }
//   }
//   .section__2 {
//     margin-right: auto !important;
//     .section__image__container2 {
//       margin-left: 0vw;
//     }
//   }
// }
