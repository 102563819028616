.div_desarrollos {
  position: relative;
  padding: 7rem 0rem 0rem 0rem;
  background-color: $bg-7;
  display: flex;
  align-items: center;
  .container-fluid {
    .container-header {
      color: $color-2;
      h3 {
        font-size: 3rem;
      }
    }
  }
}

section.projects {
  margin-bottom: 2rem;
}

@media (max-width: 767px) {
  .container-fluid {
    .container-header {
      h3 {
        font-size: 2.5rem;
      }
    }
  }
}