.nosotros {
  .content {
    .div_experiencia {
      background: $bg-0;
      .img-1 {
        position: absolute;
        width: 10%;
        top: 15%;
        left: 19%;
        z-index: 1;
        box-shadow: 0px 30px 60px #00000038;
        border-radius: 3px;
      }
      .img-2 {
        position: absolute;
        bottom: 13%;
        right: 19%;
        box-shadow: 0px 30px 60px #00000038;
        border-radius: 3px;
        width: 10%;
        z-index: 1;
      }
      div {
        height: 18rem;
        display: flex;
        align-items: center;
        h2 {
          color: $color-6;
          position: absolute;
          width: max-content;
        }
      }
      .div_first {
      }
      .second_div {
        h2 {
          left: 22%;
        }
      }
      .third_div {
        h2 {
          &:nth-last-child(2) {
            left: -6%;
          }
          &:nth-last-child(1) {
            right: -10%;
          }
        }
      }
      .quarter_div {
        h2 {
          left: -32%;
        }
      }
      .div_logo {
        position: absolute;
        bottom: -8rem;
        right: 10%;
        .div_logo_absolut {
          position: relative;
          justify-content: center;
          .logo-circle {
            z-index: 1;
          }
          .logo-simple {
            position: absolute;
            z-index: 1;
          }
        }
      }
    }
  }
}
@media (max-width: 1500px) {
  .nosotros {
    .content {
      .div_experiencia {
        .img-1 {
          width: 20%;
          top: 15%;
          left: 19%;
        }
        .img-2 {
          bottom: 13%;
          right: 19%;
          width: 20%;
        }
        div {
          h2 {
          }
        }
        .div_first {
        }
        .second_div {
          h2 {
          }
        }
        .third_div {
          h2 {
            &:nth-last-child(2) {
            }
            &:nth-last-child(1) {
            }
          }
        }
        .quarter_div {
          h2 {
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .nosotros {
    .content {
      .div_experiencia {
        .img-1 {
          width: 20%;
          top: 15%;
          left: 19%;
        }
        .img-2 {
          bottom: 13%;
          right: 19%;
          width: 20%;
        }
        div {
          h2 {
          }
        }
        .div_first {
        }
        .second_div {
          h2 {
          }
        }
        .third_div {
          h2 {
            &:nth-last-child(2) {
            }
            &:nth-last-child(1) {
              right: -25%;
            }
          }
        }
        .quarter_div {
          h2 {
          }
        }
        .logo-circle {
          bottom: -6rem;
          right: 10%;
          z-index: 1;
          width: 10rem;
        }
        .logo-simple {
          width: 4rem;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .nosotros {
    .content {
      .div_experiencia {
        .img-1 {
          width: 30%;
          top: 15%;
          left: 19%;
        }
        .img-2 {
          bottom: 13%;
          right: 19%;
          width: 30%;
        }
        div {
          height: 12rem;
          h2 {
            font-size: 7rem !important;
          }
        }
        .div_first {
        }
        .second_div {
          h2 {
          }
        }
        .third_div {
          h2 {
            &:nth-last-child(2) {
            }
            &:nth-last-child(1) {
              right: -25%;
            }
          }
        }
        .quarter_div {
          h2 {
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .nosotros {
    .content {
      .div_experiencia {
        div {
          h2 {
            font-size: 7rem !important;
          }
        }
        .div_first {
        }
        .second_div {
          h2 {
          }
        }
        .third_div {
          h2 {
            &:nth-last-child(2) {
              left: -40%;
            }
            &:nth-last-child(1) {
              right: -40%;
            }
          }
        }
        .quarter_div {
          h2 {
          }
        }
      }
    }
  }
}
