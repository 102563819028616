.header__menu__mobile {
    position: absolute;
    top: -2000%;
    left: 0;
    width: 100%;
    height: 35vh;
    background-color: $bg-1;
    opacity: 0;
    transition: all 0.3s;
    .header__menu__container {
        display: none!important;
        ul {
            li {
                a {
                    color: $color-2;
                    &.active {
                        color: $color-0;
                    }
                }
            }
        }
    }
    &.active {
        height: 100vh;
    }
}

.active {
    top: 0;
    opacity: 1;
    transition: all 0.3s;
    .header__menu__container {
        display: flex!important;
    }
}

.header__menu__mobile__icon {
    display: flex;
    width: 186px;
    height: 186px;
    background-color: $bg-0;
    z-index: 99;
    justify-content: center;
    align-items: center;
}

.header__menu__mobile__icon>img {
    width: 80px;
}

.header__button__container {
    position: absolute;
    top: 60px;
    right: 30px;
    width: 40px;
    height: 40px;
    z-index: 99;
}

.bar {
    width: 35px;
    height: 3px;
    background-color: $bg-0;
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-5px, 6px);
    transform: rotate(-45deg) translate(-5px, 6px);
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    -webkit-transform: rotate(45deg) translate(-7px, -8px);
    transform: rotate(45deg) translate(-7px, -8px);
}

@media (max-width: 992px) {
    .header__menu__mobile__icon {
        width: 150px;
        height: 150px;
    }
    .header__menu__mobile__icon>img {
        width: 120px;
    }
}